import React from 'react';
import styled from 'styled-components'
import { formatPhoneNumber } from 'utils/phoneNumber';
import { ProfileImage } from 'components/ProfileImage';

const Card = styled.div`
img{
  width : 100%;
  max-height: 275px;
  object-fit: cover;
}
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
  position: relative;
  max-width: 500px;
  overflow: hidden;
  margin-bottom: 20px;
  margin: 0 auto 20px auto;

  @media (min-width: 600px) {

    margin-bottom: 20px
  }
`

const CardBody = styled.div`
  padding: 1.5em 1.5em 1.5em 1.5em;
  position: relative;
  text-align: left;
`

const CardBusinessName = styled.div`
  color: #5E5BA7;
  font-size: 20px;
  line-height: 25px;
  position: relative;
`

const CardLocation = styled.div`
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  color: #121416;
  font-size: 14px;
  line-height: 18px;
`

const CardWebsite = styled.div`
  font-size: 12px;
  line-height: 18px;

  a {
    text-decoration: none;
    color: #0056FF;
  }

  a:Hover {
    text-decoration: underline;
  }
`

const WideCardInfoContainer = styled.div`
  display: block;
  margin: 1.5em 0 0 0;
  
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    grid-gap: 8px;
  }

  @media (min-width: 1400px) {
    grid-template-rows: 1fr;
  }
`

const CardInfoContainer = styled.div`
  display: block;
  margin: 16px 0 1.2em 0;
  
  @media (min-width: 600px) {
    display: grid;
    grid-gap: 12px;
    margin-bottom: 0;
  }

  @media (min-width: 1400px) {
    grid-template-rows: 1fr;
  }
`

const CardInfoBox = styled.div`
  color: #5E5BA7;
  font-size: 14px;
  line-height: 16px;

  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;

  a {
    text-decoration: none;
    color: #5E5BA7;
  }

  a:Hover {
    text-decoration: underline; 
  }

  grid-row: ${props => props.gridRow};
  grid-column: ${props => props.gridColumn};

  margin-bottom: 5px;

  @media (min-width: 600px) {
    margin: 0;
  }
`

const CardInfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img { 
    max-height: 16px;
  }
`

const CardServiceHeader = styled.div`
  margin: 2em 0 1em 0;
  height: 22px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  position: relative;
`

const CardServiceContainer = styled.div`
  margin-top: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
`

export const ProfileCard = ({ listing, serviceComponents, editable=false }) => {
  return (
    <Card key={`${listing.business_name}`}>
        <a href={listing.business_domain} target="_blank" rel="noreferrer">
         <ProfileImage editable={editable} listing={listing} />
        </a>
      <CardBody>
        <CardBusinessName>
          {listing.business_name}
        </CardBusinessName>

        <CardLocation>
          {listing.city}, {listing.state} {listing.zip_code}
        </CardLocation>

        <CardWebsite>
          <a href={listing.business_domain} target="_blank" rel="noreferrer" style={{color: '#3399FF'}}>Website</a>
        </CardWebsite>

        <WideCardInfoContainer>
          <CardInfoBox gridRow={1}>
            <CardInfoIcon>
              <img src="/assets/icon-radar.svg" style={{maxWidth: 20}} alt="travel radius" />
            </CardInfoIcon>
            <span><b>{listing.travel_radius}</b> Mile Travel Radius</span>
          </CardInfoBox>
        </WideCardInfoContainer>

        <CardInfoContainer>
          <CardInfoBox>
            <CardInfoIcon>
              <img src="/assets/icon-phone.svg" style={{maxWidth: 20}} alt="Phone" />
            </CardInfoIcon>

            {formatPhoneNumber(listing.phone)}
          </CardInfoBox>
          
          <CardInfoBox>
            <CardInfoIcon>
              <img src="/assets/icon-email.svg" style={{maxWidth: 18}} alt="Envelope" />
            </CardInfoIcon>

            <a href={`mailto:${listing.email}`}>
              {`${listing.business_name}`}
            </a>
          </CardInfoBox>
        </CardInfoContainer>

        <CardServiceHeader>

        </CardServiceHeader>
        <CardServiceContainer>
          {serviceComponents}
        </CardServiceContainer>
      </CardBody>
    </Card>
  )
}
