import React from 'react';
import { connect } from 'react-redux';
import { logout } from 'modules/actions/auth';
import { Redirect } from 'react-router-dom';

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
})

export const Logout = (props) => {
  React.useEffect(() => {
    props.logout();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <Redirect to="/" />;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Logout);