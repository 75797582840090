import React from 'react';
import { connect } from 'react-redux';
import { 
  incrementStep, 
  decrementStep, 
  addToRegistration, 
  submitProfile,
} from 'modules/actions/auth';
import { RegistrationSuccess } from './RegistrationSuccess';
import { RegistrationFailure } from './RegistrationFailure';

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  incrementStep: () => dispatch(incrementStep()),
  decrementStep: () => dispatch(decrementStep()),
  addToRegistration: (payload) => dispatch(addToRegistration(payload)),
  // submitRegistration: (payload) => dispatch(submitRegistration(payload)),
  submitProfile: (payload) => dispatch(submitProfile(payload)),
})


export const RegistrationResult = (props) => {
  React.useEffect(() => {
    const data = { token: props.auth.login.token, body: props.auth.registration };
    props.submitProfile(data);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if(props.auth.error)
    return <RegistrationFailure error={props.auth.error} />

  return <RegistrationSuccess />;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegistrationResult);