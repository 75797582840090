import { Types } from 'modules/types';

const headers = {
  "Accept": "application/json",
  "Content-Type": "application/json",
}

const BASE_URL = process.env.REACT_APP_API_ROOT;

export const checkBacklink = (link) => {
  return dispatch => {
    dispatch(checkBacklinkStarted());

    const url = `${BASE_URL}/lightbox/verify_link?format=json`;

    const body = { "backlink_url": link, }

    fetch(url, { method: "POST", headers, body: JSON.stringify(body) })
      .then(response => response.json())
      .then((response) => {
        if(response.status === "error") {
          dispatch(checkBacklinkFailure(response.error));
        }
        if(response.status === "ok") {
          dispatch(checkBacklinkSuccess(link));
        }
      })

  }
};

export const login = email => {
  return dispatch => {
    dispatch(loginStarted(email));

    const url = `${BASE_URL}/lightbox/login/auth/email/?format=json`;
    const body = { "email": email }

    fetch(url, { method: 'POST', headers, body: JSON.stringify(body) })
      .then((response) => response.json())
      .then((response) => {
        dispatch(loginSuccess(email));
      }).catch((error) => {
        dispatch(loginFailure("Account not found"));
      })
  }
}

export const verify = (data) => {
  return dispatch => {
    dispatch(verifyStarted(data));

    const url = `${BASE_URL}/lightbox/login/auth/token/?format=json`;

    fetch(url, { method: 'POST', headers, body: JSON.stringify(data) })
      .then((response) => { 
        if(!response.ok) {
          dispatch(verifyFailure("Invalid token entered"));
        } else {
          return response.json()
        }
      })
      .then((response) => {
        dispatch(verifySuccess(response.token));
      })
      .catch((error) => {
        dispatch(verifyFailure("Invalid token"));
      })
  }
}

export const submitRegistration = (data) => {
  return dispatch => {
    dispatch(registrationStarted(data));

    // Transforming the services into a format the API likes.
    /*
    const services = [];
    Object.entries(data.lightbox_services).map((service) => {
      if(service[1] === true) 
        return services.push({ "service_name": service[0] });
      data.services = services;
      return null;
    })
    */
    const url = `${BASE_URL}/lightbox/register`;

    fetch(url, { method: 'POST', headers, body: JSON.stringify(data) })
      .then((response) => response.json())
      .then((response) => {
        if(response.error)
          dispatch(registrationFailure(response.error));

        if(response.status === "ok")
          dispatch(registrationSuccess({ data, response }));
        
      })
  }
}

export const fetchProfile = (token) => {
  return dispatch => {
    const url = `${process.env.REACT_APP_API_ROOT}/lightbox/profile`;
    dispatch(fetchProfileStarted(token));

    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Token ${token}`,
    }
  
    fetch(url, { method: "GET", headers})
      .then(response => response.json())
      .then((response) => {
        if(response.detail === "Invalid token.") {
          dispatch(fetchProfileFailure("Invalid login"));
        } else
          dispatch(fetchProfileSuccess(response.profile));

        /*
        let newServices = {};
        response.profile.services.map((service) => {
          return newServices[[service.service_name]] = true;
        })
        */
        // setLightbox_services({...lightbox_services, ...newServices});
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchProfileFailure("An error has occurred"));
        // setError(error);
      })
    }
}

export const submitProfile = (data) => {
  return dispatch => {
    dispatch(submitProfileStarted(data));

    const url = `${process.env.REACT_APP_API_ROOT}/lightbox/profile`;

    let body = data.body;

    // Transform services for POSTing
    let services = [];

    Object.entries(body.lightbox_services).map((service) => {
      if(service[1] === true)  {
        return services.push({ "service_name": service[0] });
      }
      return null;
    })

    body.services = services;

    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Token ${data.token}`,
    }

    fetch(url, { method: "POST", headers, body: JSON.stringify(body) })
      .then(response => response.json())
      .then(response => {
        if(response.status === "ok") {
          dispatch(submitProfileSuccess(response));
        } else {
          console.log(response);
          dispatch(submitProfileFailure(response.message));
        }
        })
      .catch(error => {
        dispatch(submitProfileFailure("An error has occurred"));
      });
  }
}

export const logout = () => ({
  type: Types.LOGOUT,
});

export const incrementStep = step => ({
  type: Types.INCREMENT_STEP,
  payload: step+1,
})

export const decrementStep = step => ({
  type: Types.DECREMENT_STEP,
  payload: step-1,
})

export const addToRegistration = (payload) => ({
  type: Types.ADD_TO_REGISTRATION,
  payload: payload,
})

const checkBacklinkStarted = () => ({
  type: Types.CHECK_BACKLINK_STARTED,
})

const checkBacklinkSuccess = link => ({
  type: Types.CHECK_BACKLINK_SUCCESS,
  payload: link,
})

const checkBacklinkFailure = error => ({
  type: Types.CHECK_BACKLINK_FAILURE,
  payload: error,
})

const loginStarted = (payload) => ({
  type: Types.LOGIN_STARTED,
  payload: payload
})

const loginSuccess = (payload) => ({
  type: Types.LOGIN_SUCCESS,
  payload: payload,
})

const loginFailure = error => ({
  type: Types.LOGIN_FAILURE,
  payload: error,
})

const verifyStarted = (payload) => ({
  type: Types.VERIFY_STARTED,
  payload: payload,
})

const verifySuccess = (payload) => ({
  type: Types.VERIFY_SUCCESS,
  payload: payload,
})

const verifyFailure = error => ({
  type: Types.VERIFY_FAILURE,
  payload: error,
})

const registrationStarted = (data) => ({
  type: Types.REGISTRATION_STARTED,
  payload: data,
})

const registrationSuccess = (data) => ({
  type: Types.REGISTRATION_SUCCESS,
  payload: data,
})

const registrationFailure = error => ({
  type: Types.REGISTRATION_FAILURE,
  payload: error,
})

const submitProfileStarted = (data) => ({
  type: Types.PROFILE_UPDATE_STARTED,
  payload: data,
})

const submitProfileSuccess = (data) => ({
  type: Types.PROFILE_UPDATE_SUCCESS,
  payload: data,
})

const submitProfileFailure = error => ({
  type: Types.PROFILE_UPDATE_FAILURE,
  payload: error,
})

const fetchProfileStarted = (data) => ({
  type: Types.PROFILE_FETCH_STARTED,
  payload: data,
})

const fetchProfileSuccess = (data) => ({
  type: Types.PROFILE_FETCH_SUCCESS,
  payload: data,
})

const fetchProfileFailure = error => ({
  type: Types.PROFILE_FETCH_FAILURE,
  payload: error,
})
