import React from 'react';

import styled from 'styled-components';
import { premiumQuery } from 'queries/lightbox';
import { request } from 'graphql-request';
import PremiumContentList from 'pages/PremiumContent/PremiumContentList';
import NavBar from 'components/NavBar';
import { Throbber } from 'components';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AccessDenied } from 'components/AccessDenied';
import { fetchProfile } from 'modules/actions/auth';
import { MarginDiv } from 'primitives';
import Footer from 'pages/Landing/Footer';

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  fetchProfile: (token) => dispatch(fetchProfile(token)),
})

const Container = styled.div`
  background-color: #EFEFF7;

  grid-gap: 10px;
`
const MainContainer = styled.div`
  margin :  0 auto;
    min-height: 800px;
`

export const PremiumContent = (props) => {
  const [ data, setData ] = React.useState(null);
  
  React.useEffect(() => {
    const url = "https://api-eu-central-1.graphcms.com/v2/ckfijo0hr6n6m01z7g80r19rb/master";
    request(url, premiumQuery)
      .then((data) => {
        setData(data.lightboxPremiumContents);
      });
  }, [])

  React.useEffect(() => {
    props.fetchProfile(props.auth.login?.token);
  }, []);

  if(!data) 
    return <Throbber />;
  
  if(!props.auth.login?.token)
    return <Redirect to="/login" />;

  if(props.auth.error)
    return <React.Fragment><NavBar /><MarginDiv>{ props.auth.error }</MarginDiv></React.Fragment>

  if(!props.auth.profile)
    return <Throbber />;

  if(!props.auth.profile?.access_to_premium)
    return <React.Fragment><NavBar /><AccessDenied /></React.Fragment>;

  return (
    <React.Fragment>
      <NavBar />
      
      <Container>
        <MainContainer>
          <PremiumContentList contents={data} />
        </MainContainer>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PremiumContent);
