import styled from 'styled-components';

export const Button = styled.button`
  height: ${props => {
    if (props.height) {
      return props.height;
    } else if (props.variant === 'small') {
      return '33px';
    } else if (props.variant === 'textOnly') {
      return null;
    } else {
      return '50px';
    }
  }};
  width: ${props => {
    if (props.variant === 'small') {
      return '105px';
    } else if (props.variant === 'textOnly') {
      return null;
    } else {
      return '199px';
    }
  }};
  border-radius: ${props => {
    if (props.variant === 'small') {
      return '20px';
    } else {
      return '40px';
    }
  }};
  border: none;
  background-color: #5E5BA7;
  background: ${props => {
    if (props.variant === 'textOnly') {
      return 'unset';
    }
  }};
  padding: ${props => {
    if (props.variant === 'textOnly') {
      return '0px';
    }
  }};
  color: ${props => props.inverted ? "#757FA4" : "#fff" };
  font-size: 14px;
  font-weight: ${props => {
    if (props.variant === 'small') {
      return '600';
    } else {
      return '700';
    }
  }};
  line-height: ${props => {
    if (props.variant === 'small') {
      return '22px';
    } else {
      return '19px';
    }
  }};
  font-family: inherit;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: ${props => {
      if (props.variant !== 'textOnly') {
        return '#4c47a1';
      }
    }};
  }
`

export default Button;