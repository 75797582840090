import { gql } from 'graphql-request';

export const premiumQuery = gql`
  query LightboxPremiumQuery {
    lightboxPremiumContents {
      id
      title
      updatedAt
      stage
      publishedAt
      image {
        id
        fileName
        url
      }
      download {
        id
        url
      }
      description
      createdAt
    }
  }
`;

