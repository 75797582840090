import React from 'react';
import styled from 'styled-components';
import { gql, request } from 'graphql-request';

const Blurb = styled.div`
  a {
    color: #fff;
  }
  text-align: center;
  margin: 25px 1em 25px 1em;
  color: #757FA4;
  font-size: 18px;
  letter-spacing: -0.55px;
  line-height: 25px;
  text-align: center;
`

export const VerifyPageBlurb = () => {
  const [ blurb, setBlurb ] = React.useState(null);

  React.useEffect(() => {
    const url = "https://api-eu-central-1.graphcms.com/v2/ckfijo0hr6n6m01z7g80r19rb/master";
    const query = gql`query MyQuery {
      lightboxMarketingCopy(where: {slug: "verifyPageBlurb"}) {
        content{
          html
        }
      }
    }`

    request(url, query)
      .then((data) => {
        if(data.lightboxMarketingCopy && data.lightboxMarketingCopy.content?.html)
          setBlurb(data.lightboxMarketingCopy.content?.html || null);
      }).catch((error) => setBlurb(null))
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if(!blurb)
    return null;

  return <Blurb dangerouslySetInnerHTML={{ __html: blurb }} />;
}

export default VerifyPageBlurb;