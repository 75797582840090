import Throbber from 'components/Throbber';
import styled from 'styled-components';

const Center = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
`

export const EmptyState = () => {
  return (
    <Center>
      <Throbber />
    </Center>
  )
}