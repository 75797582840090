import styled from 'styled-components';

const Spinner = styled.div`
  margin: 100px auto 0;
  width: 100%;
  text-align: center;
  align-self: center;
  display: flex;
  align-items: center;
  position: relative;
`

const Dot = styled.div`
  width: ${props => props.active ? "32px" : "16px" };
  height: ${props => props.active ? "32px" : "16px" };
  margin: 10px;
  background-color: red;

  border-radius: 100%;
  display: inline-block;
`

const X = styled.div`
  position: absolute;
  border-left: solid 3px red;
  border-right: solid 3px red;
  height: 52px;
  width: 90px;
  left: 125px;
  margin: 0 auto;
  background-color: white;
  -moz-transform: skew(-50deg, 0deg);
  -webkit-transform: skew(-50deg, 0deg);
  -o-transform: skew(-50deg, 0deg);
  -ms-transform: skew(-50deg, 0deg);
  transform: skew(-50deg, 0deg);
`

export const FailThrobber = () => {
  return (
    <Spinner>
      <X />
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
      <Dot active></Dot>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
    </Spinner>
  )
}

export default FailThrobber;