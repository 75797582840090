import styled from 'styled-components';
import { useViewport } from 'hooks/useViewport';

const Container = styled.div`
  display: block;
  margin-top: 80px;
  margin-left: 7vw;
  margin-right: 7vw;
  margin-bottom: 90px;
  padding-top: 60px;

  @media (min-width: 800px) {
    margin: 0;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 2% 1fr 2%;
    grid-template-rows: 150px 1fr;
    grid-gap: 40px;
  }

  @media (min-width: 1200px) {
    max-width: 1280px;
    margin: 0 auto;
    grid-template-columns: 2% 1fr 1fr 2%;
  }
`

const MainContainer = styled.div`
  grid-column: 1 / span 4;
  grid-row: 1;
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;

  @media (min-width: 800px) {
    margin: 0;
    padding-right: 20px;

 
  }
`

const MainImage = styled.div`
  grid-row: 1;
  grid-column: 1 / span 2;
  margin-bottom: 10px;

  display: flex;
  justify-content: center;

  @media (min-width: 800px) {
    display: block;
  }
`

const MainHeader = styled.h2`
  color: #000;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.9px;
  line-height: 49px;
  
  margin-bottom: 40px;

  display: flex;
  justify-content: center;

  @media (min-width: 800px) {
    display: block;

    grid-row: 1;
    font-size: 32px;
    letter-spacing: -1.1px;
    line-height: 60px;
    padding-bottom: 20px
  }
`

const Info1Container = styled.div`
  grid-column: 2;


  @media (min-width: 1300px) {
    display: grid;

  }
`

const Info1Logo = styled.div`
  grid-row: 1;

  margin-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 60px;
  }
`

const Info1Image = styled.div`
  grid-row: 1;

  margin-bottom: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1300px) {
    grid-row: 2;
  }

  img {
    border-radius: 5px;
  }
`

const Info1Text = styled.p`
  grid-row: 3;

  color: #000;
  font-size: 14px;
  line-height: 16px;
  
  margin-bottom: 30px;

  @media (min-width: 1300px) {
    line-height: 24px;
  }
`

const Info2Container = styled.div`
  grid-column: 2;
  grid-row: 3;

  @media (min-width: 1300px) {
    grid-column: 3;
    grid-row: 2;

    display: grid;
    grid-template-rows: 90px 200px;
  }
`

const Info2Logo = styled.div`
  grid-row: 1;

  margin-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 70px;
  }
`

const Info2Image = styled.div`
  grid-row: 1;

  margin-bottom: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1300px) {
    grid-row: 2;
  }

  img {
    border-radius: 5px;
  }
`

const Info2Text = styled.p`
  grid-row: 3;

  color: #000;
  font-size: 14px;
  line-height: 16px;

  margin-bottom: 60px;

  @media (min-width: 1300px) {
    line-height: 24px;
  }
`

const CanonLogos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin-bottom: 50px;
`
const Logo = styled.img`
  max-width: 140px;
  height: 30px;

  @media(min-width: 800px) {
    height: auto;
  }
`

const CanonEol = styled.img`
  max-width: 140px
`
const CanonLogo = styled.img`
  max-width: 140px
`

export const Panel2 = () => {
  const { width } = useViewport();

  if (width < 800) {
    return (

      <Container>
        <MainImage>
          <Logo src={`/assets/logo-lb-dark.png`} alt="Lightbox logo" />
        </MainImage>

        <MainHeader>
          In Partnership with
        </MainHeader>

        <Info1Logo>
          <img src="/assets/stripling_logo.png" alt="susan stripling logo" />
        </Info1Logo>

        <Info1Image>
          <img src="/assets/susan-stripling.jpg" alt="susan stripling" />
        </Info1Image>

        <Info1Text>
          <a href="https://www.susanstripling.com/">Susan Stripling</a> is a prominent New York wedding photographer specializing in creative, timeless wedding photography with a modern appeal. Best known for her creative approach to composition, documentary style of capturing natural moments, and her heartfelt dedication towards customer service. Susan holds the prestigious distinction of being named a <a href="https://www.usa.canon.com/internet/portal/us/home/explore/explorers-of-light/explorer-susan-stripling">“Canon Explorer of Light.”</a>
        </Info1Text>

        <CanonLogos>
            <CanonEol src={`/assets/canon-eol.png`}  alt="canon"  />
            <CanonLogo src={`/assets/logo_1956.png`} alt="canon logo" />
        </CanonLogos>

        <Info2Logo>
          <img src="/assets/theimagesalon_mainlogo.png" alt="theimagesalon logo" />
        </Info2Logo>

        <Info2Image>
          <img src="/assets/image_salon.jpg" alt="a standard office" />
        </Info2Image>

        <Info2Text>
          <a href="https://imagesalon.com/">Image Salon</a> is a photography and post-production studio in Montreal, Canada. Founded in 2014, by photographers, the team includes 130+ editors and retouchers. Clients span many genres: real estate, product, wedding, event, portrait, fashion, as well as big brands in the travel, hospitality, and real estate industries.
        </Info2Text>
      </Container>

    )
  } else if (width < 1300) {
    return (

      <Container>
        <MainContainer>
          <MainImage>
            <Logo src={`/assets/logo-lb-dark.png`} alt="Lightbox logo" />
          </MainImage>

          <MainHeader>
            In Partnership with
          </MainHeader>
        </MainContainer>

        <Info1Container>
          <Info1Logo>
            <img src="/assets/stripling_logo.png" alt="susan stripling logo" />
          </Info1Logo>

          <Info1Image>
            <img src="/assets/susan-stripling.jpg" alt="susan stripling" />
          </Info1Image>

          <Info1Text>
            <a href="https://www.susanstripling.com/">Susan Stripling</a> is a prominent New York wedding photographer specializing in creative, timeless wedding photography with a modern appeal. Best known for her creative approach to composition, documentary style of capturing natural moments, and her heartfelt dedication towards customer service. Susan holds the prestigious distinction of being named a <a href="https://www.usa.canon.com/internet/portal/us/home/explore/explorers-of-light/explorer-susan-stripling">“Canon Explorer of Light.”</a>
          </Info1Text>

          <CanonLogos>
            <CanonEol src={`/assets/canon-eol.png`}  alt="canon"  />
            <CanonLogo src={`/assets/logo_1956.png`} alt="canon logo" />
          </CanonLogos>

          <Info2Logo>
            <img src="/assets/theimagesalon_mainlogo.png" alt="theimagesalon logo" />
          </Info2Logo>

          <Info2Image>
            <img src="/assets/image_salon.jpg" alt="a standard office" />
          </Info2Image>

          <Info2Text>
            <a href="https://imagesalon.com/">Image Salon</a> is a photography and post-production studio in Montreal, Canada. Founded in 2014, by photographers, the team includes 130+ editors and retouchers. Clients span many genres: real estate, product, wedding, event, portrait, fashion, as well as big brands in the travel, hospitality, and real estate industries.
          </Info2Text>
        </Info1Container>
      </Container>

    )
  } else {
    return (

      <Container>
        <MainContainer>
          <MainImage>
            <Logo src={`/assets/logo-lb-dark.png`} alt="Lightbox logo" />
          </MainImage>

          <MainHeader>
            In Partnership with
          </MainHeader>
        </MainContainer>

        <Info1Container>
          <Info1Logo>
            <img src="/assets/stripling_logo.png" alt="susan stripling logo" />
          </Info1Logo>

          <Info1Image>
            <img src="/assets/susan-stripling.jpg" alt="susan stripling" />
          </Info1Image>

          <Info1Text>
            <a href="https://www.susanstripling.com/">Susan Stripling</a> is a prominent New York wedding photographer specializing in creative, timeless wedding photography with a modern appeal. Best known for her creative approach to composition, documentary style of capturing natural moments, and her heartfelt dedication towards customer service. Susan holds the prestigious distinction of being named a <a href="https://www.usa.canon.com/internet/portal/us/home/explore/explorers-of-light/explorer-susan-stripling">“Canon Explorer of Light.”</a>
          </Info1Text>

          <CanonLogos>
            <CanonEol src={`/assets/canon-eol.png`}  alt="canon"  />
            <CanonLogo src={`/assets/logo_1956.png`} alt="canon logo" />
          </CanonLogos>
        </Info1Container>

        <Info2Container>
          <Info2Logo>
            <img src="/assets/theimagesalon_mainlogo.png" alt="theimagesalon logo" />
          </Info2Logo>

          <Info2Image>
            <img src="/assets/image_salon.jpg" alt="a standard office" />
          </Info2Image>

          <Info2Text>
            <a href="https://imagesalon.com/">Image Salon</a> is a photography and post-production studio in Montreal, Canada. Founded in 2014, by photographers, the team includes 130+ editors and retouchers. Clients span many genres: real estate, product, wedding, event, portrait, fashion, as well as big brands in the travel, hospitality, and real estate industries.
          </Info2Text>
        </Info2Container>
      </Container>

    )
  }
}

export default Panel2;