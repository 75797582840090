import React from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import StyledInput from 'components/StyledInput';
import { MarginDiv } from 'primitives';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from 'modules/actions/auth';
import LoginPageBlurb from 'components/blurbs/LoginPageBlurb';

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  login: (email) => dispatch(login(email)),
})

const FormContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  width: 100%;
  margin-top: 50px;
  max-width: 400px;
  padding-bottom: 50px;
`

const InputContainer = styled.div`
  padding: 0 22px 0 23px;
  text-align: center;
`

const StyledFormTitle = styled.div`
  padding: 100px 0 0px 0;
  color: #757FA4;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
`

const ErrorDiv = styled.div`
  margin: 20px 0 20px 0;
  color: red;
`
const BlurbBlock = styled.div`
p{
  font-size: 14px;
  color: #000;
    a{
    font-size: 14px;
    color: #000;
    }
  }

`

export const LoginForm = (props) => {
  const form = React.useRef(null);
  const [ email, setEmail ] = React.useState("");

  const handleSubmit = event => {
    event.preventDefault();

    const valid = form.current.reportValidity();
    if(valid) {
      props.login(email);
      // props.incrementStep();
    }
  }

  return (
    <React.Fragment>
      <FormContainer>
        <InputContainer>
          <StyledFormTitle>Login</StyledFormTitle>
          <BlurbBlock><LoginPageBlurb /></BlurbBlock>
          <form ref={form} onSubmit={handleSubmit}>
            <StyledInput required type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>

            { props.auth.error ? <ErrorDiv>{props.auth.error}</ErrorDiv> : null }

            <MarginDiv>
              <Button onClick={handleSubmit}>Continue</Button>
            </MarginDiv>
          </form>

          <MarginDiv>
            <Link to="/">
              <Button variant="textOnly" inverted>
                Cancel
              </Button>
            </Link>
          </MarginDiv>
        </InputContainer>
      </FormContainer>
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);