import styled from 'styled-components';
import Button from 'components/Button';
import { useViewport } from 'hooks/useViewport';

import { Link } from 'react-router-dom';

const Background = styled.div`
     background-color:  #F7F7FB;
     padding-bottom: 1px;

`
const Container = styled.div`
  display: block;
  margin-top: 80px;
  margin-left: 7vw;
  margin-right: 7vw;
  margin-bottom: 90px;


  @media (min-width: 650px) {
    height: 750px;

    margin: 0;

    display: grid;
    grid-template-columns: 2% 45% 1fr 2%;
    grid-template-rows: 80px 1fr;
    grid-gap: 40px;
  }

  @media (min-width: 1000px) {
    height: 850px;
    
    background-image: url(/assets/section-pm-graphic.png);
    background-origin: border-box;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
    background-size: auto;
  }

  @media (min-width: 1200px) {
    max-width: 1280px;
    margin: 0 auto;
    min-height: 800px;
    grid-template-columns: 2% 40% 1fr 1fr 2%;

    background-position-x: 50%;
  }
`

const MainContainer = styled.div`
  grid-column: 2;
  grid-row: 2;

  margin-left: 30px;
  margin-right: 30px;

  @media (min-width: 650px) {
    margin: 0;
    padding-right: 20px;

    max-width: 600px;
  }
`

const MainLabel = styled.div`
  grid-row: 1;

  color: #7171FF;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.6px;
  line-height: 33px;

  margin-bottom: 25px;

  @media (min-width: 650px) {
    font-size: 20px;
  }
`

const MainHeader = styled.h2`
  color: #000;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -0.9px;
  line-height: 49px;
  
  margin-bottom: 40px;

  @media (min-width: 650px) {
    grid-row: 2;
    font-size: 44px;
    letter-spacing: -1.1px;
    line-height: 60px;
  }
`

const ViewDirectoryButtonContainer = styled.div`
  grid-row: 4;

  display: flex;
  justify-content: center;

  margin-bottom: 20px;

  @media (min-width: 650px) {
    display: block;
  }
`

const Info1Container = styled.div`
  grid-column: 3;
  grid-row: 2;
`

const Info1Icon = styled.div`
  grid-row: 1;

  margin-bottom: 10px;
`

const Info1Header = styled.h3`
  grid-row: 2;

  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
`

const Info1Text = styled.p`
  grid-row: 3;

  color: #000;
  font-size: 14px;
  line-height: 24px;

  margin-bottom: 30px;
`

const Info2Container = styled.div`
  grid-column: 3;
  grid-row: 3;

  @media (min-width: 1200px) {
    grid-column: 4;
    grid-row: 2;
  }
`

const Info2Icon = styled.div`
  grid-row: 1;

  margin-bottom: 10px;
`

const Info2Header = styled.h3`
  grid-row: 2;

  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
`

const Info2Text = styled.p`
  grid-row: 3;

  color: #000;
  font-size: 14px;
  line-height: 24px;

  margin-bottom: 60px;
`

const Icon = styled.img`
  max-width: 40px;
  margin-right: 10px;
`;


export const Panel2 = () => {
  const { width } = useViewport();

  if (width < 650) {
    return (
      <Background>
      <Container>
        <MainLabel>
          For Property Managers
        </MainLabel>

        <MainHeader>
         Beautiful rental listings start here.
        </MainHeader>

        <Info1Icon>
          <Icon alt="Virtual icon" src={`/assets/icon-virtual.svg`} />
        </Info1Icon>
        
        <Info1Header>
          The New Normal is Virtual
        </Info1Header>

        <Info1Text>
          In the Age of Covid, renters are far less likely to tour a property in-person. Managers who show unit specific images, tours and details will win the hearts and minds of renters today.
        </Info1Text>

        <Info2Icon>
          <Icon alt="Visuals icon" src={`/assets/icon-visuals.svg`} />
        </Info2Icon>
        
        <Info2Header>
          Better Visuals = More Rent
        </Info2Header>

        <Info2Text>
          Your property gets one chance to capture a renter’s attention through its first impression. Especially in when social distancing is the norm, Renters will skip properties that lack unit specific images.
        </Info2Text>

        <ViewDirectoryButtonContainer>
          <Link to="/directory">
            <Button>
              View Directory
            </Button>
          </Link>
        </ViewDirectoryButtonContainer>
      </Container>
    </Background>
    )
  } else if (width < 1200) {
    return (
      <Background>
        <Container>
          <MainContainer>
            <MainLabel>
              For Property Managers
            </MainLabel>

            <MainHeader>
              A new standard for listing properties starts here.
            </MainHeader>

            <ViewDirectoryButtonContainer>
              <Link to="/directory">
                <Button>
                  View Directory
                </Button>
              </Link>
            </ViewDirectoryButtonContainer>
          </MainContainer>

          <Info1Container>
            <Info1Icon>
              <Icon alt="Virtual icon" src={`/assets/icon-virtual.svg`} />
            </Info1Icon>
            
            <Info1Header>
              The New Normal is Virtual
            </Info1Header>

            <Info1Text>
              In the Age of Covid, renters are far less likely to tour a property in-person. Managers who show unit specific images, tours and details will win the hearts and minds of renters today.
            </Info1Text>

            <Info2Icon>
              <Icon alt="Visuals icon" src={`/assets/icon-visuals.svg`} />
            </Info2Icon>
            
            <Info2Header>
              Better Visuals = More Rent
            </Info2Header>

            <Info2Text>
              Your property gets one chance to capture a renter’s attention through its first impression. Especially in when social distancing is the norm, Renters will skip properties that lack unit specific images.
            </Info2Text>
          </Info1Container>
        </Container>
      </Background>
    )
  } else {
    return (
      <Background>
        <Container>
          <MainContainer>
            <MainLabel>
              For Property Managers
            </MainLabel>

            <MainHeader>
              Beautiful rental listings start here.
            </MainHeader>

            <ViewDirectoryButtonContainer>
              <Link to="/directory">
                <Button>
                  View Directory
                </Button>
              </Link>
            </ViewDirectoryButtonContainer>
          </MainContainer>

          <Info1Container>
            <Info1Icon>
              <Icon alt="Virtual icon" src={`/assets/icon-virtual.svg`} />
            </Info1Icon>
            
            <Info1Header>
              The New Normal is Virtual
            </Info1Header>

            <Info1Text>
              In the Age of Covid, renters are far less likely to tour a property in-person. Managers who show unit specific images, tours and details will win the hearts and minds of renters today.
            </Info1Text>
          </Info1Container>

          <Info2Container>
            <Info2Icon>
            <Icon alt="Visuals icon" src={`/assets/icon-visuals.svg`} />
            </Info2Icon>
            
            <Info2Header>
              Better Visuals = More Rent
            </Info2Header>

            <Info2Text>
              Your property gets one chance to capture a renter’s attention through its first impression. Especially in when social distancing is the norm, Renters will skip properties that lack unit specific images.
            </Info2Text>
          </Info2Container>
        </Container>
      </Background>
    )
  }
}

export default Panel2;