import Panel1 from './Panel1';
import Panel2 from './Panel2';
import Panel3 from './Panel3';
import Panel4 from './Panel4';
import Panel5 from './Panel5';
import Footer from './Footer';

export const Landing = () => {
  return (
    <>
      <Panel1 />
      <Panel2 />
      <Panel3 />
      <Panel4 />
      <Panel5 />
      <Footer />
    </>
  );
};

export default Landing;