import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
    max-width: 600px;
    margin: 0 auto;
    height: 80vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`


const Paragraph = styled.p``


export const AccessDenied = (props) => {
  return (
    <Container>
      <Paragraph>
        In order to get access to premium content, your profile must be complete and your website backlink to Dwellsy must be working. Go to  <Link to="/my">Profile</Link> to make updates.
      </Paragraph>
    </Container>
  )
}

export default AccessDenied;