import React from 'react';
import styled from 'styled-components';

const Entry = styled.div`
  display: grid;
  grid-template-columns: 50px 40px 200px;
  align-items: center;
  font-size: 14px;

  img {
    max-height: 24px;
  }
`

const StyledCheckbox = styled.input`
  width: 17px;
  height: 17px;
  display: inline-block;
  margin: 12px;
`

export const validateServices = services => {
  let trueKeys = [];

  Object.entries(services).map((key) => {
    if(key[1] === true)
      return trueKeys.push(key[0]);
    return false;
  })

  return trueKeys.length;
}

export const ServicesSelector = (props) => {
  const handleChange = event => {
    props.onChange(event);
  }

  return (
    <React.Fragment>
      <Entry>
        <StyledCheckbox 
          name="PHOTOSTILLS" 
          type="checkbox" 
          checked={props.services.PHOTOSTILLS}
          onChange={handleChange} 
        /><img src="/assets/portrait.png" alt="Portrait" /> Still Photography<br />
      </Entry>
      <Entry>
        <StyledCheckbox 
          name="PHOTO360" 
          type="checkbox" 
          checked={props.services.PHOTO360}
          onChange={handleChange}
        /><img src="/assets/360.png" alt="360 / Matterport" /> 360&deg; / Matterport<br />
      </Entry>
      <Entry>
        <StyledCheckbox 
          name="INTERIORSTYLING" 
          type="checkbox" 
          checked={props.services.INTERIORSTYLING}
          onChange={handleChange}
        /><img src="/assets/interiorStyling.png" alt="Interior Styling" />Interior Styling<br />
      </Entry>
      <Entry>
        <StyledCheckbox 
          name="VIDEO" 
          type="checkbox" 
          checked={props.services.VIDEO}
          onChange={handleChange}
        /><img src="/assets/videography.png" alt="videography" />Videography<br />
      </Entry>
      <Entry>
        <StyledCheckbox 
          name="PHOTOAERIAL" 
          type="checkbox" 
          checked={props.services.PHOTOAERIAL}
          onChange={handleChange}
        /><img src="/assets/aerialPhotography.png" alt="Aerial Photography" />Aerial Photography<br />
      </Entry>
    </React.Fragment>
  )
}