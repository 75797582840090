import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PhotographerOptions from 'components/PhotographerOptions';
import { Link } from 'react-router-dom';
import { ProfileCard } from 'components/ProfileCard/ProfileCard';
import { cardServiceComponentMap } from 'components/ProfileCard/CardServiceMap';
import { EmptyState } from 'components/EmptyState/EmptyState';
import Footer from 'pages/Landing/Footer';

const Container = styled.div`
  background: #EFEFF7;
`

const HeaderContainer = styled.div`
  height: 73px;


  background-image: linear-gradient(130.1deg, #5E5BA7 0%, #1E133E 45%);

  display: grid;
  grid-template-columns: 5vw 1fr 1fr 1fr 5vw;
  align-items: center;

  @media (min-width: 600px) {
    height: 153px;

  }
`

const PhotographerOptionsContainer = styled.div`
  grid-column: 4;
  justify-self: end;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (min-width: 600px) {
    justify-self: center;
  }
`

const LightboxLogoContainer = styled.div`
  grid-column: 3;
  justify-self: center;

  @media (min-width: 600px) {
    grid-column: 2;
  }
`

const LightboxLogo = styled.img`
  height: 27px;

  @media (min-width: 600px) {
    height: 45px;
  }
`

const FilterContainer = styled.div`

  justify-content: center;
  max-width: 980px;
  margin: 20px auto;
  font-size: 14px;
  p{
    font-size: 16px;
    color: #666;
    font-weight: 400;
    padding: 0 20px
  }
`

const MessageContainer = styled.div`

  padding-bottom: 100px;
  justify-content: center;
  max-width: 980px;
  margin: 20px auto;
  font-size: 14px;
  p{
    font-size: 16px;
    color: #666;
    font-weight: 400;
    padding: 0 20px
  }
`


const FilterChrome = styled.div`
  padding: 40px 10px; 

  border-radius: 6px;
  width: 100%;

  input{
    align-self: center;
    width: 100%;
  }
`

const HelperText = styled.div`
  font-size: 10px;
  padding: 10px;
  font-style: italics;
`

const Filter = styled.input` 
  height: 50px;
  width: 250px;
  border-radius: 8px;
  border: none;
  background-color: #FFF;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.13);
  padding-left: 20px;

  ::placeholder {
    color: #5E5BA7;
  }
  
  font-size: 14px;
  line-height: 18px;

  @media (min-width: 350px) {
    width: 329.7px;
  }

  @media (min-width: 600px) {
    width: 525px;
  }
`



const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 950px) {
    display: grid;
    grid-gap: 20px;

    grid-template-columns: 413px 413px;
  }

  @media (min-width: 1400px) {
    grid-template-columns: 413px 413px 413px;
  }

  margin-left: 5vw;
  margin-right: 5vw;
  padding-bottom: 200px;
`

export const Directory = () => {
  const [directory, setDirectory] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [ loading, setLoading ] = useState(false);

  const DIRECTORY_API_URL = `${process.env.REACT_APP_API_ROOT}/lightbox/directory?format=json`;

  // fetch directory listings from api on initialize
  useEffect(() => {
    setLoading(true);
    fetch(DIRECTORY_API_URL)
      .then(response => response.json())
      .then(data => {
        setDirectory(data);
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeFilterString = (event) => {
    setFilterString(event.target.value);
  };

  const concatString = (listing) => {
    const b = listing.business_name ? listing.business_name.toLowerCase() : "";
    const c = listing.city ? listing.city.toLowerCase() : "";
    const s = listing.state ? listing.state.toLowerCase() : "";
    const z = listing.zip_code ? listing.zip_code.toString().toLowerCase() : "";

    const y = listing.services.map((service) => {
      return `${service.service_name} `;
    })
    const combinedSearchFields = `${b} ${c} ${s} ${z} ${y}`;
    return combinedSearchFields;
  }

  return (
    <Container>
      <HeaderContainer>
        <LightboxLogoContainer>
          <Link to="/">
            <LightboxLogo src="/assets/logo-lb.png" alt="Lightbox logo" />
          </Link>
        </LightboxLogoContainer>

        <PhotographerOptionsContainer>
          <PhotographerOptions />
        </PhotographerOptionsContainer>
      </HeaderContainer>

      <FilterContainer>
        <FilterChrome>
          <Filter 
            placeholder="Filter by Name, City, Service or Zip Code"
            value={filterString}
            onChange={handleChangeFilterString}
          />
          <HelperText>eg. Still Photography, Video, Aerial, 360, Matterport, and Styling</HelperText>
        </FilterChrome>
      </FilterContainer>

      { loading ? <EmptyState /> : null }

      <CardContainer>
        {directory.map((listing, index) => {
          // const b = listing.business_name ? listing.business_name.toLowerCase() : "";
          // const c = listing.city ? listing.city.toLowerCase() : "";
          // const s = listing.state ? listing.state.toLowerCase() : "";
          // const z = listing.zip_code ? listing.zip_code.toString().toLowerCase() : "";
          // const combinedSearchFields = `${b} - ${c} - ${s} - ${z}`;
          const combinedSearchFields = concatString(listing);


          if (combinedSearchFields.toLowerCase().includes(filterString.toLowerCase())) {
            const serviceNames = listing.services.map(service => service.service_name).sort();
            const serviceComponents = serviceNames.map(name => cardServiceComponentMap[name]);
            return <ProfileCard editable={false} listing={listing} serviceComponents={serviceComponents} key={`profileCard-${index}`} />
          } else {
            return null;
          }
        })}
      </CardContainer>
        <MessageContainer>
        <p>Results are sorted first by Dwellsy Community Builders; then by zip code. Once you find the right professional, all terms are between the two of you. Dwellsy takes no role in the transaction, no commission from either party, and does not claim any right to the images that are created as a result.</p>
      </MessageContainer>
      <Footer/>
    </Container>
  )
}

export default Directory;