export const Types = {
  // Registration
  REGISTRATION_STARTED: "REGISTRATION_STARTED",
  REGISTRATION_SUCCESS: "REGISTRATION_SUCCESS",
  REGISTRATION_FAILURE: "REGISTRATION_FAILURE",
  INCREMENT_STEP: "INCREMENT_STEP",
  DECREMENT_STEP: "DECREMENT_STEP",
  ADD_TO_REGISTRATION: "ADD_TO_REGISTRATION",

  // Backlink Processing
  CHECK_BACKLINK_STARTED: "CHECK_BACKLINK_STARTED",
  CHECK_BACKLINK_SUCCESS: "CHECK_BACKLINK_SUCCESS",
  CHECK_BACKLINK_FAILURE: "CHECK_BACKLINK_FAILURE",

  // Login
  LOGIN_STARTED: "LOGIN_STARTED",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  VERIFY_STARTED: "VERIFY_STARTED",
  VERIFY_SUCCESS: "VERIFY_SUCCESS",
  VERIFY_FAILURE: "VERIFY_FAILURE",
  LOGOUT: "LOGOUT",

  // Profile
  PROFILE_UPDATE_STARTED: "PROFILE_UPDATE_STARTED",
  PROFILE_UPDATE_SUCCESS: "PROFILE_UPDATE_SUCCESS",
  PROFILE_UPDATE_FAILURE: "PROFILE_UPDATE_FAILURE",
  PROFILE_FETCH_STARTED: "PROFILE_FETCH_STARTED",
  PROFILE_FETCH_SUCCESS: "PROFILE_FETCH_SUCCESS",
  PROFILE_FETCH_FAILURE: "PROFILE_FETCH_FAILURE",

}

export default Types;