import React from 'react';
import styled from 'styled-components';

const StyledImageUpload = styled.div`
  display: grid;
  background-color: #F7F7F7;
  background-image: url(${props => props.image});
  min-height: 220px;
  align-items: center;
  justify-items: center;
  grid-template-rows: 130px 30px 30px;
  position: relative;
`

const EnvelopeImage = styled.div`
  margin: 40px 0 20px 0;
  background-color: #6FCBED;
  display: grid;
  align-items: center;
  align-self: center;
  justify-content: center;
  border-radius: 100%;
  height: 72px;
  width: 72px;
  position: relative;
`

const AddLogoText = styled.div`
  color: #757FA4;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
`

const MinDimensions = styled.div`
  height: 13px;
  color: #4F4F4F;
  font-size: 12px;
  line-height: 13px;
`

export const ImageWidget = (props) => {
  const [ result, setResult ] = React.useState(null);
  const preface = "https://res.cloudinary.com/dwellsy/image/upload/h_235,c_scale";

  React.useEffect(() => {
    props.onChange({ "business_image": result });
    // props.addToRegistration({ "business_image": result });
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadWidget = () => {
    window.cloudinary.openUploadWidget({ cloud_name: 'dwellsy', 
    theme: 'minimal',
    showPoweredBy: false,
    max_width: '350',
    styles:{
      width: "100%",
       palette: {
        window: "#FFF",
        windowBorder: "#5E5BA7",
        tabIcon: "#5E5BA7",
        menuIcons: "#5E5BA7",
        textDark: "#000000",
        textLight: "#FFFFFF",
        link:  "#5E5BA7",
        action:  "#5E5BA7",
        inactiveTabIcon: "#5E5BA7",
        error: "#F44235",
        inProgress: "#0078FF",
        complete: "#20B832",
        sourceBg: "#E4EBF1"
      },
     
    },
    
    upload_preset: 'dwellsy-lightbox'}, (error, results) => {
      try {
        const result = results[0];
        const output = `${preface}/v${result.version}/${result.public_id}`;
        setResult(output);
      } catch(e) {
        setResult(null);
      }
    })
  }

  if(result) {
    if(props.editable)
      return <StyledImageUpload onClick={uploadWidget} image={result}></StyledImageUpload>
    return <StyledImageUpload image={result}></StyledImageUpload>
  }

  return (
    <StyledImageUpload onClick={uploadWidget}>
      <EnvelopeImage>
        <img src="/assets/bigEnvelope.png" alt="Envelope" /> 
      </EnvelopeImage>
      <AddLogoText>
        Add Logo or Sample Image
      </AddLogoText>
      <MinDimensions>
        Image should be 3:2 formatted to display properly.
      </MinDimensions>
    </StyledImageUpload>
  )
}

export default ImageWidget;