import React from 'react';
import styled from 'styled-components';
import { DwellsyLogo, LightboxLogo } from 'components';
import InitialForm from 'components/RegistrationForm/InitialForm';
import ServiceSelectionForm from 'components/RegistrationForm/ServiceSelectionForm';
import LinkVerificationForm from 'components/RegistrationForm/LinkVerificationForm';
import { connect } from 'react-redux'
import RegistrationResult from 'components/RegistrationForm/RegistrationResult';
import GenericBlurb from 'components/blurbs/GenericBlurb';
import RegistrationVerifyForm from 'components/LoginForm/RegistrationVerifyForm';

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
})


const Background = styled.div`
  min-height: 100vh;
  width: 100vw;
  background-image: url(/assets/shapes.png), linear-gradient(130.1deg, #5E5BA7 0%, #1E133E 100%);
  background-origin: border-box;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position-x: 100%, right, 0px;
  background-position-y: 45%, bottom, center;
  background-size: auto, 100%, auto;
  padding: 15px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1000px)  auto;

`
const Container = styled.div`
  grid-column : 2
`

const GridContainer = styled.div`

  display: grid;
  grid-column : 2;

  @media (min-width: 820px) {
    grid-template-columns: repeat(4,1fr);
  }
`
const MainContainer = styled.div`

  grid-column: 1 / span 1;
  
  @media (min-width: 820px) {
    grid-column: 1 / 3;
  }
`
const FormContainer = styled.div`

  @media (min-width: 820px) {
    grid-column: 3 / 5;
  }
`
const MainHeader = styled.h2`
  color: white;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  padding-right: 2em;
`


export const Register = (props) => {
  return (
     <Background>
      <Container>
        <GridContainer>
          <MainContainer>
            <DwellsyLogo />
            <LightboxLogo />
            <MainHeader><GenericBlurb slug="joinPageHeader" /></MainHeader>
          </MainContainer>
          <FormContainer>
            {/*
            { props.auth.step === 0 ? <InitialForm /> : null }
            { props.auth.step === 1 ? <RegistrationVerifyForm /> : null }
            */}
            { props.auth.step === 1 ? <ServiceSelectionForm /> : null }
            { props.auth.step === 2 ? <LinkVerificationForm /> : null }
            { props.auth.step === 3 ? <RegistrationResult /> : null }
          </FormContainer>
        </GridContainer>
      </Container>
    </Background>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Register);