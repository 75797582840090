import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { MarginDiv } from 'primitives';

const ItemDiv = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  max-width: 960px;
  /* width: 100vw; */
  margin: 0 auto;

  @media (min-width: 600px) {

    grid-template-columns: 30% 70%;
  
  }
`

const ListImage = styled.div`
  color: #efeff7;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 19px;
  display: flex;
`

const DetailImage = styled.img`
  max-width: 120px;
  width: 100%;
  align-self: center;
   @media (min-width: 600px) {

   align-self: center;
   
    }

`

const ListTitle = styled.h1`

    font-size: 18px;
    font-weight: 600;
    display: block;
    line-height: 22px;

  @media (min-width: 600px) {
    font-size: 28px;
    display: block;
    line-height: 38px;
  }

`

const ListDetail = styled.div`
margin-top: 30px

`

const ListDescription = styled.div`
   margin-top:14px;
  display: block;
  color: #495052;

   @media (min-width: 600px) {
  margin-top:20px;
  display: block;
  color: #495052;
  }
`

const ItemImage = ({ item }) => {
  if(!item.image) 
    return null;

  return <DetailImage src={item.image.url} alt={item.title} />
}

const ItemDownloadButton = ({ item }) => {
  if(!item.download) 
    return null;

  return (
    <a href={item.download.url}>
      <Button>
        Download
      </Button>
    </a>
  )
}

export const PremiumContentList = (props) => {
  return props.contents.map((item) => {
    return (
      <ItemDiv key={item.id}>
        <ListImage>
          <ItemImage item={item} />
        </ListImage>

        <ListDetail>
          <ListTitle>{item.title}</ListTitle>

          <MarginDiv top="20px">
            <ListDescription>{item.description}</ListDescription>
          </MarginDiv>

          <MarginDiv top="50px">
            <ItemDownloadButton item={item} />
          </MarginDiv>
        </ListDetail>
      </ItemDiv>
    )
  })
}

export default PremiumContentList;