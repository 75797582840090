import React from 'react';
import styled from 'styled-components';
import FailThrobber from 'components/FailThrobber';

const FailedVerificationText = styled.h4`
  font-weight: bold;
  font-size: 14px;
  color: red;
  margin: 80px 0 -80px 0;
`

export const VerifyingLinkFailed = () => {
  return (
    <React.Fragment>
      <FailedVerificationText>Verification Failed</FailedVerificationText>
      <FailThrobber />
    </React.Fragment>
  )
}

export default VerifyingLinkFailed;