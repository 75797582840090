import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledDwellsyLogo = styled.div`
  grid-row: 1;
    max-width: 120px
`

const DwellsyLogoImg = styled.img`
  height: 80px;

  @media (min-width: 600px) {
  max-height: 120px
  }
`

export const DwellsyLogo= () => {
  return (
    <StyledDwellsyLogo>
      <Link to="/">
        <DwellsyLogoImg src="/assets/logo.png" alt="Dwellsy logo" />
      </Link>
    </StyledDwellsyLogo>
  )
}

export default DwellsyLogo;