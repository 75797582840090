import styled from 'styled-components';

const Container = styled.div`
  display: block;
  margin-top: 50px;
  margin-bottom: 80px;
  margin-left: 7vw;
  margin-right: 7vw;

  @media (min-width: 600px) {

    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 90% 1fr;
    grid-template-rows: 0px 100px 1fr 50px;
  }
`

const Background = styled.div`
     background-color:  #F7F7FB;
     padding-top: 100px;

`

const HeaderContainer = styled.div`
  grid-column: 2;
  grid-row: 2;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 30px;

  @media (min-width: 600px) {
    display: block;
    margin: 0;
  }
`

const Header = styled.h2`
  color: #000;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -0.9px;
  line-height: 49px;

  @media (min-width: 600px) {
    font-size: 44px;
    letter-spacing: -1.1px;
    line-height: 60px;
  }
`

const CardContainer = styled.div`
  grid-column: 2;
  grid-row: 3;
  
  display: flex;
  flex-direction: column;
  align-items: center;


  @media (min-width: 600px) {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr;
    column-gap: 5%;

    margin: 0;
  }

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5%;
  }
`

const InfoCard = styled.div`
`

const InfoCardHeader = styled.h3`
  font-size: 20px;
  line-height: 22px;
`

const InfoCardText = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 25px;
`

export const Panel5 = () => {
  return (
  <Background>
    <Container>
      <HeaderContainer>
       
       <a href="#faq" style={{textDecoration: 'none'}} name="faq"> <Header> Background & FAQ</Header> </a>
      </HeaderContainer>

      <CardContainer>
        <InfoCard>
          <InfoCardHeader>
            How did Dwellsy Lightbox come to be?
          </InfoCardHeader>

          <InfoCardText>
            Dwellsy Lightbox was born in a time of COVID-19, when social distancing simultaneously decimated the wedding and event photography industry AND caused a spike in demand for unit-specific rental photography (since units couldn’t be shown in person). Dwellsy has a mission to do well by doing good and we knew that we could bring these two groups together for the benefit of all. At a time there is so much we can’t do to help the world, doing this felt like a no-brainer.
          </InfoCardText>
        </InfoCard>

        <InfoCard>
          <InfoCardHeader>
            Does Dwellsy own the images or collect a commission?
          </InfoCardHeader>

          <InfoCardText>
            No and no! We aren’t involved in payment or terms and we *definitely* do NOT own the images! We make a marketplace where talent meets demand and everything else is between the two parties.
          </InfoCardText>
        </InfoCard>

        <InfoCard>
          <InfoCardHeader>
            Do I have to list my rental units on Dwellsy to use Lightbox?
          </InfoCardHeader>

          <InfoCardText>
            No, of course not! But since listing your rental units on Dwellsy is ALSO <a href="https://dwellsy.com/rental-property-managers/">really, truly free</a>, why would you not?
          </InfoCardText>
        </InfoCard>

        <InfoCard>
          <InfoCardHeader>
            Do I have to use Lightbox photographers for my listings on Dwellsy?
          </InfoCardHeader>

          <InfoCardText>
            No, of course not! You can use any imagery you have. (But they really are a very talented bunch so we’d hate for you to miss out!)
          </InfoCardText>
        </InfoCard>

        <InfoCard>
          <InfoCardHeader>
            Free? C’mon…what’s in it for Dwellsy?
          </InfoCardHeader>

          <InfoCardText>
            Good will and good karma. Seriously. We think that the <a href="https://blog.dwellsy.com/a-full-fledged-duopoly/">pay-to-play</a> model that is forced on property managers is toxic to the entire rental industry, especially renters! Dwellsy is on a mission to chart a new path. When a company can do something that helps people in a time of crisis, and the cost is minimal, a better question would be, How could we not?
          </InfoCardText>
        </InfoCard>

        <InfoCard>
          <InfoCardHeader>
            Can Real Estate agents use Dwellsy Lightbox for their for-sale listings?
          </InfoCardHeader>
          
          <InfoCardText>
            Yes! Dwellsy, as a listing site, serves exclusively long-term rental homes, but anyone who needs real estate or property images is welcome to use it. Enjoy!
          </InfoCardText>
        </InfoCard>
      </CardContainer>
    </Container>
  </Background>
  )
}

export default Panel5;