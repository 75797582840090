import React from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import { MarginDiv } from 'primitives';
import { connect } from 'react-redux';
import { incrementStep, decrementStep, addToRegistration } from 'modules/actions/auth';
import { Link } from 'react-router-dom';

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  incrementStep: () => dispatch(incrementStep()),
  decrementStep: () => dispatch(decrementStep()),
  addToRegistration: (payload) => dispatch(addToRegistration(payload)),
})

const FormContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  min-height: 800px;
  min-width: 414px;
  padding: 2em 0;
`

const InputContainer = styled.div`
  padding: 0 22px 0 23px;
  text-align: center;
`

const Paragraph = styled.div`
  height: 74px;
  color: #121416;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`

const StyledFormTitle = styled.div`
  color: red;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
`

export const RegistrationFailure = (props) => {
  return (
    <React.Fragment>
      <FormContainer>
        <InputContainer>
          <MarginDiv top="91px">
            <StyledFormTitle>Error</StyledFormTitle>
          </MarginDiv>
          
          <MarginDiv top="20px">
            <Paragraph>
              {props.error}
            </Paragraph>
          </MarginDiv>

          <MarginDiv>
            <Link to="/premium">
              <Button>View Premium Content</Button>
            </Link>
          </MarginDiv>

          <MarginDiv>
            <Link to="/my">
              <Button variant="textOnly" inverted>View Profile</Button>
            </Link>
          </MarginDiv>
        </InputContainer>
      </FormContainer>
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegistrationFailure);