import { Image, Transformation } from 'cloudinary-react';

export const CloudinaryImage = (props) => {
  let img;
  if(props.business_image.startsWith("https://res.cloudinary")) {
    var parts = props.business_image.split("/");
    img = `${parts[parts.length-3]}/${parts[parts.length-2]}/${parts[parts.length-1]}`;

    return (
      <Image cloudName="Dwellsy" publicId={img} secure="true">
        <Transformation height="280" crop="fill" />
      </Image>
    )
  } else {
    return (
      <Image 
        cloudName="Dwellsy" 
        publicId={props.business_image} 
        height="280" 
        secure="true" 
      />
    )
  }
}