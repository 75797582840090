import React from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import StyledInput from 'components/StyledInput';
import { MarginDiv } from 'primitives';
import { connect } from 'react-redux';
import { incrementStep, decrementStep, addToRegistration, checkBacklink } from 'modules/actions/auth';
import VerifyingLink from './verifyingLinkState';
import VerifyingLinkFailed from './verifyingLinkFailure';
import VerifyingLinkSuccess from './verifyingLinkSuccess';

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  decrementStep: () => dispatch(decrementStep()),
  incrementStep: () => dispatch(incrementStep()),
  addToRegistration: (payload) => dispatch(addToRegistration(payload)),
  checkBacklink: (payload) => dispatch(checkBacklink(payload)),
})

const FormContainer = styled.div`
  padding: 2em 0 0 0;
  background-color: white;
  border-radius: 4px;
  min-height: 94vh;
  max-width: 450px;
  width: 100%;
`

const CheckboxContainer = styled.div`
  text-align: left;
`

const InputContainer = styled.div`
  padding: 0 20px;
  text-align: center;
`

const StyledFormTitle = styled.div`

  color: #757FA4;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
`
const Subtext = styled.div`
 
  color: #757FA4;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
`
const HelpText = styled.div`
 p{
   font-size: 14px
 }
`
const ProgressContainer = styled.div`
  margin: 48px 0 48px 0;
  height: 222px;
  text-align: center;
  display: grid;
  vertical-align: center;
  justify-content: center;
`

export const LinkChecker = (props) => {
  if(props.auth.registration.loading) 
    return <VerifyingLink />

  if(props.auth.registration.error) 
    return <VerifyingLinkFailed />
  
  if(props.auth.registration.backlink_url) {
    setTimeout(() => {
      props.incrementStep();
    }, 800);
    return <VerifyingLinkSuccess />
  }

  if(!props.auth.registration.backlink_url)
    return <img src="/assets/backlink.png" alt="Backlink" /> 
}

export const ServiceSelectionForm = (props) => {
  const [ loading, setLoading ] = React.useState(false);
  const [ backlink_url, setBacklink_url ] = React.useState(props.auth.registration.backlink_url);

  const handleSubmit = () => {
    props.checkBacklink(backlink_url);
    setLoading(!loading);
  }

  return (
    <React.Fragment>
      <FormContainer>
        <InputContainer>
          <MarginDiv>
            <StyledFormTitle>Backlink Checker</StyledFormTitle>
            <HelpText>
            <p>A backlink on your website to<br />https://dwellsy.com/lightbox<br />is required to join.</p>
            <p>Enter the URL on YOUR WEBSITE<br />where the backlink can be found.</p>
            </HelpText>
          </MarginDiv>

          <CheckboxContainer>
            <StyledInput label="backlink" type="text" onChange={(e) => setBacklink_url(e.target.value)} defaultValue={backlink_url} placeholder="https://www.yoursite.com/realestate" />
          </CheckboxContainer>
          <ProgressContainer>
            <LinkChecker {...props} />
          </ProgressContainer>
          <Subtext>
              Having problems? <a href="https://blog.dwellsy.com/tips-dwellsy-lightbox/">Click here</a>
          </Subtext>
          <MarginDiv top="30px">
            <Button onClick={handleSubmit}>Continue</Button>
          </MarginDiv>
          <MarginDiv>
            <Button onClick={props.decrementStep} variant="textOnly" inverted>
              Back
            </Button>
          </MarginDiv>
        </InputContainer>
      </FormContainer>
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceSelectionForm);