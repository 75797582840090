import React from 'react';
import styled from 'styled-components';
import NavBar from 'components/NavBar';
import { ProfileCard } from 'components/ProfileCard/ProfileCard';
import { cardServiceComponentMap } from 'components/ProfileCard/CardServiceMap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Throbber } from 'components';
import { MarginDiv } from 'primitives';

const mapStateToProps = state => ({
  auth: state.auth,
})

const Container = styled.div`
  display: grid;
  background-color: #EFEFF7;
  align-items: start;
  padding: 2em 0 0 0;
  justify-self: center;
  justify-content: center;
  grid-template-columns: repeat(20, 5vw);
  height: 100%;
`

const MainContainer = styled.div`
  grid-column: 4 / 10;
  max-width: 1280px;
  margin: 0 auto;
  margin: 30px 0;
  @media (max-width: 851px) {
    display: none;
  }
  min-height: 81vh;
`

const ProfileContainer = styled.div`
  grid-column: 11/20;
  @media (max-width: 850px) {
    grid-column: 2/18;
  }
  max-width: 414px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Title = styled.h1`
  color: #000;
  font-size: 44px;
  font-weight: 500;
  letter-spacing: -1.1px;
  line-height: 50px;
`

const TextContainer = styled.div`
  grid-column: 2/10;
  max-width: 1280px;
  margin: 0 auto;
  margin: 30px 0;
  color: #000;
  font-size: 14px;
  line-height: 24px;

  @media (max-width: 800px){
    display: none;
  }
`

export const MyLightbox = (props) => {
  const [ profile, setProfile ] = React.useState(null);
  const url = `${process.env.REACT_APP_API_ROOT}/lightbox/profile`;
  const [ error, setError ] = React.useState(null);

  const getProfileData = (token) => {
    setError(null);
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Token ${token}`,
    }

    fetch(url, { method: "GET", headers})
      .then((response) => response.json())
      .then((response) => {
        setProfile(response.profile);
      })
      .catch((error) => {
        setError(error);
      })
  }

  React.useEffect(() => {
    getProfileData(props.auth.login.token);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if(!props.auth.login?.token)
    return <Redirect to="/login" />

  if(!profile) 
    return <Throbber />

  if(error)
    return <React.Fragment><NavBar /><Container><MarginDiv>{error}</MarginDiv></Container></React.Fragment>;

  const serviceNames = profile.services.map(service => service.service_name).sort();
  const serviceComponents = serviceNames.map(name => cardServiceComponentMap[name]);
  const editable = true;

  return (
    <React.Fragment>
      <NavBar />
      <Container>
        <MainContainer>
          <TextContainer>
            <Title>
              Expand your reach, skills, and portfolio
            </Title>
            <MarginDiv>
              Thousands of property managers use Dwellsy for their 
              listings and they need great marketing images. Joining
              Lightbox is a free, easy way to get exposure to these 
              prospective clients.
            </MarginDiv>
          </TextContainer>
        </MainContainer>
        <ProfileContainer>
          <ProfileCard listing={profile} serviceComponents={serviceComponents} editable={editable} />
        </ProfileContainer>
      </Container>
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps
)(MyLightbox);

