import { useState, useRef } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import StyledInput from 'components/StyledInput';
import { MarginDiv } from 'primitives';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
  incrementStep, 
  decrementStep, 
  addToRegistration, 
  submitRegistration 
} from 'modules/actions/auth';
import { JoinPageBlurb } from 'components/blurbs/JoinPageBlurb';
import ImageUploader from 'components/ImageUploader/ImageUploader';
import { TelephoneInput, ZipCodeInput } from 'components/forms';
import { Errors } from 'components/DjangoErrors';
import { ButtonLoading } from 'components/ButtonLoading';
import Footer from 'pages/Landing/Footer';

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  incrementStep: () => dispatch(incrementStep()),
  decrementStep: () => dispatch(decrementStep()),
  addToRegistration: (payload) => dispatch(addToRegistration(payload)),
  submitRegistration: (payload) => dispatch(submitRegistration(payload)),
})

const FormContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  min-height: 94vh;
  width: 100%;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.34);
  overflow: hidden
`

const InputContainer = styled.div`
  padding: 0 20px 0 20px;
  text-align: center;
`

const SmallText = styled.p`
  font-size: 10px;
  text-align: left;
  margin-top: 2px
  margin-bottom: 12px
`


export const InitialForm = (props) => {
  const [ business_name, setBusiness_name ] = useState(props.auth.registration.business_name || '');
  const [ zip, setZip ] = useState(props.auth.registration.zip_code || '');
  const [ phone, setPhone ] = useState(props.auth.registration.phone || '');
  const [ email, setEmail ] = useState(props.auth.registration.email || '');
  const [ business_domain, setBusiness_domain ] = useState(props.auth.registration.business_domain || '');
  const form = useRef(null);

  const handleSubmit = event => {
    event.preventDefault();
    const valid = form.current.reportValidity();

    if (valid) {
      const data = {
        business_name,
        zip_code: zip, 
        phone,
        email,
        business_domain,
      }
      props.addToRegistration(data)
      props.submitRegistration({ ...props.auth.registration, ...data });
      // props.incrementStep();
    }
  }

  return (
    <FormContainer>
      <ImageUploader onChange={(e) => props.addToRegistration(e)} />
      <JoinPageBlurb />

      <InputContainer>
        { props.auth.error ? <Errors errors={props.auth.error} /> : null }

        <form ref={form} onSubmit={handleSubmit}>
          <StyledInput 
            required 
            type="text" 
            name="business_name"
            placeholder="Business Name" 
            pattern="[a-zA-Z0-9 ]+" 
            onChange={(e) => setBusiness_name(e.target.value)} 
            value={business_name} 
            aria-label="Business Name"
          />
          <SmallText>Business Name can only contain letters and numbers</SmallText>


          <ZipCodeInput value={zip} onChange={(value) => setZip(value)} aria-label="Zip Code" />

          <TelephoneInput value={phone} onChange={(value) => setPhone(value)} aria-label="Telephone" />

          <StyledInput 
            required 
            type="email" 
            placeholder="Email" 
            onChange={(e) => setEmail(e.target.value)} 
            value={email}   
            aria-label="Email"
          />

          <StyledInput 
            required 
            type="url" 
            placeholder="Website (eg https://yoursite.com)" 
            onChange={(e) => setBusiness_domain(e.target.value)} 
            value={business_domain} 
            aria-label="Business Website"
          />
          <SmallText>Web address must include http or https.</SmallText>

          <MarginDiv>
            <Button 
              onClick={handleSubmit}
            >
              { props.auth.loading ? <ButtonLoading /> : "Continue" }
            </Button>
          </MarginDiv>
        </form>

        <MarginDiv>
          <Link to="/">
            <Button variant="textOnly" inverted>
              Cancel
            </Button>
          </Link>
        </MarginDiv>
      </InputContainer>

    </FormContainer>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InitialForm);