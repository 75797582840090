import { useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import Button from 'components/Button';
import { useViewport } from 'hooks/useViewport';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  auth: state.auth,
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
 
`

const PhotographerOptionsContainer = styled.div`
  border-radius: 5px;
  padding-top: 5px;
  width: 100px;
  position: absolute;
  top: 50px;
  z-index: 40;

  @media (min-width: 600px) {
    position: static;
    background: none;
    display: grid;

    grid-template-columns: 10vw 1fr;
    grid-template-rows: 20px 1fr;

    border: none;
    width: auto;
    height: auto;
  }
  @media (min-width: 800px) {
    grid-template-columns: 90px 1fr;
  }
`

const PhotographerOptionsLabel = styled.div`
  color: #7171FF;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 19px;

  @media (min-width: 600px) {
    grid-column: 1 / span 2;
    grid-row: 1;
  }
`

const PhotographerOptionsSignIn = styled.div`
border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;

  @media (min-width: 600px) {
    background-color: #5E5BA7;
    grid-column: 1;
    grid-row: 2;
    justify-content: start;
    background: unset;
    border: none;
    padding: none;
  }
`

const PhotographerOptionsSignUp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #5E5BA7;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;

  padding-top: 6px;
  padding-bottom: 6px;


  @media (min-width: 600px) {
    grid-column: 2;
    grid-row: 2;

    justify-content: start;

    background: unset;
    border: none;
    padding: none;
  }
`


const PhotographerOptionsProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: trnapsarent;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;

  padding-top: 6px;
  padding-bottom: 6px;


  @media (min-width: 600px) {
    grid-column: 2;
    grid-row: 2;

    justify-content: start;
    background-color: #5E5BA7;
    background: unset;
    border: none;
    padding: none;
  }
`

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`

export const PhotographerOptions = (props) => {
  const { width } = useViewport();
  const [photographerOptionsVisible, setPhotographerOptionsVisible] = useState(false);

  const imgNode = useRef();
  const button1Node = useRef();
  const button2Node = useRef();

  useEffect (() => {
    const handleClickDocument = (event) => {
      if (
        photographerOptionsVisible && 
        !imgNode.current.contains(event.target) && 
        !button1Node.current.contains(event.target) && 
        !button2Node.current.contains(event.target)
      ) {
        setPhotographerOptionsVisible(false);
      }
    }

    // the hamburger button to open the photographer options popover is mobile-only
    if (width < 600) {
      document.addEventListener('mousedown', handleClickDocument);

      return () => {
        document.removeEventListener('mousedown', handleClickDocument);
      }
    }
  }, [photographerOptionsVisible, width]); // eslint-disable-line react-hooks/exhaustive-deps

  const togglePhotographerOptionsVisible = () => {
      setPhotographerOptionsVisible(!photographerOptionsVisible);
  };

  if (width < 600) {
    if(props.auth.login?.token) {
      return (
        <Container>
          <img
            src="/assets/hamburger-button.png" 
            alt="open photographer menu" 
            onClick={togglePhotographerOptionsVisible}  
            ref={imgNode}
          />
          
          {photographerOptionsVisible && 
            <PhotographerOptionsContainer>
              <PhotographerOptionsSignIn ref={button1Node}>
                <Button variant='small'>
                  <StyledLink to="/logout">
                    Sign Out
                  </StyledLink>
                </Button>
              </PhotographerOptionsSignIn>
            
              <PhotographerOptionsProfile ref={button2Node}>
                <Link to="/my">
                  <Button variant='small'>
                    Profile
                  </Button>
                </Link>
              </PhotographerOptionsProfile>
            </PhotographerOptionsContainer>
          }
        </Container>

      )
    }
    return (
      <Container>
        <img
          src="/assets/hamburger-button.png" 
          alt="open photographer menu" 
          onClick={togglePhotographerOptionsVisible}  
          ref={imgNode}
        />
        
        {photographerOptionsVisible &&
          <PhotographerOptionsContainer>
            <PhotographerOptionsSignIn ref={button1Node}>
              <StyledLink to="/login">
                Sign In
              </StyledLink>
            </PhotographerOptionsSignIn>

            <PhotographerOptionsSignUp ref={button2Node}>
              <StyledLink to="/join">
                Sign Up
              </StyledLink>
            </PhotographerOptionsSignUp>
          </PhotographerOptionsContainer>
        }
      </Container>
    )
  } else {
    if(props.auth.login?.token) {
      return (
        <PhotographerOptionsContainer>
          <PhotographerOptionsLabel>For Photographers</PhotographerOptionsLabel>
          <PhotographerOptionsSignIn>
            <Link to="/logout">
              <Button variant='textOnly'>Sign Out</Button>
            </Link>
          </PhotographerOptionsSignIn>

          <PhotographerOptionsSignUp>
            <Link to="/my">
              <Button variant='small'>
                Profile
              </Button>
            </Link>
          </PhotographerOptionsSignUp>

        </PhotographerOptionsContainer>
      )
    }

    return (
      <PhotographerOptionsContainer>
        <PhotographerOptionsLabel>For Photographers</PhotographerOptionsLabel>
        <PhotographerOptionsSignIn>
          <Link to="/login">
            <Button variant='textOnly'>
              Sign In 
            </Button>
          </Link>
        </PhotographerOptionsSignIn>

        <PhotographerOptionsSignUp>
          <Link to="/join">
            <Button variant='small'>
              Sign Up
            </Button>
          </Link>
        </PhotographerOptionsSignUp>
      </PhotographerOptionsContainer>
    )
  }
}

export default connect(mapStateToProps)(PhotographerOptions);