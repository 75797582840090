import React from 'react';
import styled from 'styled-components';
import SuccessThrobber from 'components/SuccessThrobber';

const VerificationSuccessText = styled.h4`
  font-weight: bold;
  font-size: 14px;
  color: green;
  margin: 80px 0 -80px 0;
`

export const VerifyingLinkSuccess = () => {
  return (
    <React.Fragment>
      <VerificationSuccessText>Verification Succeeded</VerificationSuccessText>
      <SuccessThrobber />
    </React.Fragment>
  )
}

export default VerifyingLinkSuccess;