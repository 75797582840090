import React from 'react';
import styled from 'styled-components'
import { gql, request } from 'graphql-request';
import htmr from 'htmr';

const Blurb = styled.div`
  a {
    color: #fff;
  }
  text-align: center;
  color: #fff;
  font-size: 14px;
  letter-spacing: -0.55px;
  line-height: 25px;
  text-align: left;
   @media (min-width: 820px) {
    font-size: 18px;

  }
`

const EmptyState = styled.div`
  min-height: 100px;
`

export const GenericBlurb = (props) => {
  const [ blurb, setBlurb ] = React.useState(null);

  React.useEffect(() => {
    const url = "https://api-eu-central-1.graphcms.com/v2/ckfijo0hr6n6m01z7g80r19rb/master";
    const query = gql`query MyQuery {
      lightboxMarketingCopy(where: {slug: "${props.slug}"}) {
        content{
          html
        }
      }
    }`

    request(url, query)
      .then((data) => {
        if(data.lightboxMarketingCopy && data.lightboxMarketingCopy.content?.html)
          setBlurb(data.lightboxMarketingCopy.content?.html || null);
      }).catch((error) => setBlurb(null))
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if(!blurb)
    return <EmptyState />;

  return <Blurb>{htmr(blurb)}</Blurb>;
}

export default GenericBlurb;