import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import StyledInput from 'components/StyledInput';
import { MarginDiv } from 'primitives';
import { connect } from 'react-redux';
import { incrementStep, addToRegistration } from 'modules/actions/auth';
import { ButtonLoading } from 'components/ButtonLoading';

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  incrementStep: () => dispatch(incrementStep()),
  addToRegistration: (payload) => dispatch(addToRegistration(payload)),
})

const FormContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  width: 100%;
  padding: 2em 0;
`

const CheckboxContainer = styled.div`
  text-align: left;
`

const InputContainer = styled.div`
  padding: 0 22px 0 23px;
  text-align: center;
`

const StyledFormTitle = styled.div`
  color: #757FA4;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  width: 100%;
  text-align: center;
`

const StyledCheckbox = styled.input`
  width: 17px;
  height: 17px;
  display: inline-block;
  margin: 17px;
`

const Entry = styled.div`
  display: grid;
  grid-template-columns: 50px 40px 200px;
  align-items: center;
  font-size: 14px;

  img {
    max-height: 30px;
  }
`

const ErrorsDiv = styled.div`
  margin: 1em 0;
  font-weight: 500;
  color: red;
  text-align: center;
`


export const ServiceSelectionForm = (props) => {
  const [ radius, setRadius ] = useState(props.auth.registration.travel_radius);
  const form = useRef(null);
  const [ errors, setErrors ] = useState(false);
  const [ lightbox_services, setLightbox_services ] = useState({
    "PHOTOSTILLS": false,
    "PHOTO360": false,
    "INTERIORSTYLING": false,
    "VIDEO": false,
    "PHOTOAERIAL": false,
  });

  const testServiceSelection = (services) => {
    const values = Object.values(services);
    const truthy = (element) => element === true;

    if(values.some(truthy)) {
      setErrors(null);
      return values.some(truthy);
    }

    setErrors("At least one service must be selected");
    return false;
  }

  useEffect(() => {
    if(testServiceSelection(lightbox_services)) {
      props.addToRegistration(lightbox_services);
      props.addToRegistration({ lightbox_services: lightbox_services });
    } 
  }, [lightbox_services]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = event => {
    event.preventDefault();

    const servicesValid = testServiceSelection(lightbox_services);
    const valid = form.current.reportValidity();

    if(servicesValid && valid) {
      props.addToRegistration({ 
        travel_radius: radius,
      })
      props.incrementStep();
    }
  }

  const handleChange = event => {
    setLightbox_services({ 
      ...lightbox_services, 
      [event.target.name]: event.target.checked 
    })
  }

  return (
    <React.Fragment>
      <FormContainer>
        <InputContainer>
          <form ref={form} onSubmit={handleSubmit}>
            <CheckboxContainer>
              <MarginDiv top="91px">
                <StyledFormTitle>What Service Do You Offer?</StyledFormTitle>
              </MarginDiv>

              { errors ? <ErrorsDiv>{ errors }</ErrorsDiv> : null }

              <Entry>
                <StyledCheckbox 
                  name="PHOTOSTILLS" 
                  type="checkbox" 
                  defaultChecked={lightbox_services.PHOTOSTILLS}
                  onChange={handleChange} 
                /><img src="/assets/portrait.png" alt="Portrait" /> Still Photography<br />
              </Entry>
              <Entry>
                <StyledCheckbox 
                  name="PHOTO360" 
                  type="checkbox" 
                  defaultChecked={lightbox_services.PHOTO360}
                  onChange={handleChange}
                /><img src="/assets/360.png" alt="360 / Matterport" /> 360&deg; / Matterport<br />
              </Entry>
              <Entry>
                <StyledCheckbox 
                  name="INTERIORSTYLING" 
                  type="checkbox" 
                  defaultChecked={lightbox_services.INTERIORSTYLING}
                  onChange={handleChange}
                /><img src="/assets/interiorStyling.png" alt="Interior Styling" />Interior Styling<br />
              </Entry>
              <Entry>
                <StyledCheckbox 
                  name="VIDEO" 
                  type="checkbox" 
                  defaultChecked={lightbox_services.VIDEO}
                  onChange={handleChange}
                /><img src="/assets/videography.png" alt="videography" />Videography<br />
              </Entry>
              <Entry>
                <StyledCheckbox 
                  name="PHOTOAERIAL" 
                  type="checkbox" 
                  defaultChecked={lightbox_services.PHOTOAERIAL}
                  onChange={handleChange}
                /><img src="/assets/aerialPhotography.png" alt="Aerial Photography" />Aerial Photography<br />
              </Entry>

              <MarginDiv top="28px" bottom="0" >
                <StyledFormTitle>Service radius (in miles)</StyledFormTitle>
              </MarginDiv>
              <StyledInput type="number" style={{maxWdith: '250px'}} min="1" max="3000" required placeholder="enter a number of miles" onChange={(e) => setRadius(e.target.value)} defaultValue={radius} />
            </CheckboxContainer>
            <MarginDiv>
              <Button 
                type="submit" 
                onClick={handleSubmit}
              >
                { props.auth.loading ? <ButtonLoading /> : "Continue" }
              </Button>
            </MarginDiv>
          </form>
        </InputContainer>
      </FormContainer>
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceSelectionForm);