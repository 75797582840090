import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useViewport } from 'hooks/useViewport';

const NavContainer = styled.div`
  background: linear-gradient(156.1deg, #5E5BA7 0%, #1E133E 50%); 
`;

const TabBar = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-gap: 10px;
  justify-content: center;
  justify-items: center;

  @media (min-width: 600px) {
    grid-template-columns: 1fr repeat(3, fit-content(50px));
    grid-gap: 20px;
    justify-items: center;
  }
`

const TabContainer = styled.div`
  display: grid;
  max-width: 1280px;
  margin: 0 auto;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;

  @media (min-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
  }
`
const TabButton = styled.div`
  padding: 10px 24px;
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  background: transparent;
  outline: none;
  transition: border-color 0.2s ease-in;
    border-bottom: 2px solid transparent;
  &:hover, &:focus, &:active {
    border-bottom: 2px solid #8BA4FF;
    color: #8BA4FF
  }
`;

const LightboxLogoImg = styled.img`
  height: 25px;

  @media (min-width: 600px) {
    height: 35px;
  }
`

const LightboxLogo = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`
const StyledLink = styled(Link)`
  align-items: center;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  background: transparent;
  text-decoration: none;
`;

const HamburgerButtonContainer = styled.div`
  grid-column: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 20px;
`

const NavMenu = styled.div`
  position: absolute;
  top: 70px;
  z-index: 4000;

  background: #5E5BA7;

  border: 2px solid black;
`

const NavMenuOption = styled.div`
  border: 1px solid black;
`

export const NavBar = () => {
  const { width } = useViewport();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const menuNode = useRef();
  
  if (width < 800) {
    return (
      <NavContainer>
        <TabContainer>
          <LightboxLogo>
            <Link to="/">
              <LightboxLogoImg src="/assets/logo-lb.png" alt="Lightbox logo" />
            </Link>
          </LightboxLogo>

          <HamburgerButtonContainer>
            <img 
              src="/assets/hamburger-button.png" 
              alt="open nav menu" 
              onClick={() => setHamburgerOpen(!hamburgerOpen)}
              ref={menuNode}
            />
            {hamburgerOpen && 
              <NavMenu>
                <NavMenuOption>
                  <StyledLink to="/premium">Premium Content</StyledLink>
                </NavMenuOption>
                
                <NavMenuOption>
                  <StyledLink to="/my">Profile</StyledLink>
                </NavMenuOption>
                
                <NavMenuOption>
                  <StyledLink to="/directory">View Directory</StyledLink>
                </NavMenuOption>
                
                <NavMenuOption>
                  <StyledLink to="/logout">Sign out</StyledLink>
                </NavMenuOption>
              </NavMenu>
            }
          </HamburgerButtonContainer>
        </TabContainer>

      </NavContainer>
    )
  } else {
    return (
      <NavContainer>
        <TabContainer>
          <LightboxLogo>
            <Link to="/">
              <LightboxLogoImg src="/assets/logo-lb.png" alt="Lightbox logo" />
            </Link>
          </LightboxLogo>
          <TabBar>
            <TabButton>
              <StyledLink to="/premium">Premium Content</StyledLink>
            </TabButton>
            <TabButton>
              <StyledLink to="/my">Profile</StyledLink>
            </TabButton>
            <TabButton>
              <StyledLink to="/directory">View Directory</StyledLink>
            </TabButton>
            
          </TabBar>
          <StyledLink to="/logout">Sign out</StyledLink>
          
        </TabContainer>
      </NavContainer>
    )
  }
  
}

export default NavBar;