import StyledInput from 'components/StyledInput';

export const ZipCodeInput = (props) => {
  const handleZipChange = event => {
    const value = event.target.value

    let isNumber = /^\d+$/.test(value); //tests whether the value contains digits only

    if (isNumber) {
      props.onChange(value);
      // setZip(value);
    } else if (!value) {
      props.onChange(value);
      // setZip(value);
    }
  };

  return (
    <StyledInput 
      required 
      type="text" 
      placeholder="US Zip Code" 
      maxLength="5"
      onChange={handleZipChange} 
      value={props.value} 
      aria-label="Zip code"
    />
  )
}
