import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import NavBar from 'components/NavBar';
import { Redirect  } from 'react-router-dom';
import { MarginDiv } from 'primitives';
import { cardServiceComponentMap } from 'components/ProfileCard/CardServiceMap';
import { Button, StyledInput } from 'components';
import { TelephoneInput, ZipCodeInput } from 'components/forms';
import { submitProfile, fetchProfile } from 'modules/actions/auth';
import { ButtonLoading } from 'components/ButtonLoading';
import { ProfileCard } from 'components/ProfileCard/ProfileCard';
import { ProfileImage } from 'components/ProfileImage';
import { ServicesSelector } from 'components/forms/ServiceSelector';
import Footer from 'pages/Landing/Footer';

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  submitProfile: (data) => dispatch(submitProfile(data)),
  fetchProfile: (token) => dispatch(fetchProfile(token)),
})

const CardOverlay = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  padding-top: 2em;
  background-color: rgba(0, 0, 0, 0.85);
  text-align: center;
  overflow: auto;
  z-index: 900;
`

const Card = styled.div`
  position: relative;
  opacity: 1.0;
    top: 20px;
  margin: 0 auto;
  width:  100%;
  max-width: 414px;
`

const ErrorDiv = styled.div`
  font-weight: 500;
  color: red;
  justify-self: center;
  margin: 2em 0 2em 0;
`

const Container = styled.div`
  background-color: #EFEFF7;
  padding-top: 1em;
  align-items: start;
  align-self: start;
  margin-left: 50px;
  margin-right: 50px;

  @media (min-width: 600px) {
    margin: 0;
    display: grid;
    grid-gap: 20px;
    grid-template-rows: auto;
    grid-template-columns: 1fr minmax(30%, 500px) minmax(30%, 500px) 1fr;
  }
`

const Panel0 = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #EFEFF7;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #5E5BA7;
  text-align: center;
  grid-column: 1;
  grid-row: 1;

  @media (min-width: 600px) {
    grid-column: 2 / 4;
    height: 100%;
  }
`

const Panel1 = styled.div`
  grid-column: 2;
  grid-row: 2;

  @media (min-width: 600px) {
  }
`

const Panel2 = styled.div`
  grid-column: 2;
  grid-row: 3;

  @media (min-width: 600px) {
    grid-column: 3;
    grid-row: 2;
  }
`

const Panel3 = styled.div`
  text-align: center;
`

const Body = styled.div`
  text-align: center;
  background-color: #EFEFF7;
  padding-bottom: 100px
`

const StyledFormTitle = styled.div`
  color: #757FA4;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  width: 100%;
  text-align: left;
  margin: 22px 0 0 0;
`

export const EditProfile = (props) => {
  const [ cardShown, setCardShown ] = useState(false);

  const loadCard = event => {
    event.preventDefault();
    setCardShown(true);
  }

  React.useEffect(() => {
    props.fetchProfile(props.auth.login.token);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    let newServices = {};
    props.auth.profile?.services.map((service) => {
      return newServices[[service.service_name]] = true;
    })
    setLightbox_services(newServices);
    setEmail(props.auth.profile?.email);
    setRadius(props.auth.profile?.travel_radius);
    setZip(props.auth.profile?.zip_code);
    setPhone(props.auth.profile?.phone);
    setBusiness_name(props.auth.profile?.business_name);
    setBusiness_domain(props.auth.profile?.business_domain);
    setBusiness_image(props.auth.profile?.business_image);
    setBacklink_url(props.auth.profile?.backlink_url);
    setFirst_name(props.auth.profile?.first_name);
    setLast_name(props.auth.profile?.last_name);
  }, [props.auth.profile])

  const [ first_name, setFirst_name ] = useState('');
  const [ last_name, setLast_name ] = useState('');
  const [ business_name, setBusiness_name ] = useState('');
  const [ business_image, setBusiness_image ] = useState(null);
  const [ zip, setZip ] = useState('');
  const [ phone, setPhone ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ business_domain, setBusiness_domain ] = useState('');
  const [ backlink_url, setBacklink_url ] = useState('');
  const form = useRef(null);
  const [ radius, setRadius ] = React.useState(props.auth.registration.travel_radius);
  const [ lightbox_services, setLightbox_services ] = React.useState({
    "PHOTOSTILLS": false,
    "PHOTO360": false,
    "INTERIORSTYLING": false,
    "VIDEO": false,
    "PHOTOAERIAL": false,
  });

  const handleBiz = (e) => {
    console.log("*******")
    console.log(e);
    console.log("*******")
  }

  if(!props.auth.login?.token)
    return <Redirect to="/login" />

  // if(!props.auth.profile) 
    // return <React.Fragment><NavBar /><EmptyState /></React.Fragment>
  
  if(props.auth.error)
    return <React.Fragment><NavBar /><ErrorDiv>{ props.auth.error }</ErrorDiv></React.Fragment>;

  if(props.auth.profile === null) {
    return null;
  }

  const serviceNames = props.auth.profile.services.map(service => service.service_name).sort();
  const serviceComponents = serviceNames.map(name => cardServiceComponentMap[name]);

  const handleSubmit = event => {
    event.preventDefault();
    const valid = form.current.reportValidity();

    if (valid) {
      const formData = {
        first_name,
        last_name,
        business_name,
        zip_code: zip,
        phone,
        email,
        business_domain,
        backlink_url,
        travel_radius: radius,
        lightbox_services,
        business_image
      }
      props.submitProfile({ token: props.auth.login.token, body: formData });
      // updateProfile(props.auth.login.token, formData);
    }
  }

  const handleServicesChange = event => {
    var services = { ...lightbox_services }
    services[[event.target.name]] = !services[[event.target.name]];
    setLightbox_services(services);
  }

  return (
    <React.Fragment>
      <Body>
      <form ref={form} onSubmit={handleSubmit}>

      {cardShown &&
        <CardOverlay>
          <Card>
            <ProfileCard listing={props.auth.profile} serviceComponents={serviceComponents} />
          </Card>
          <Button variant="textOnly" onClick={() => setCardShown(false)}>
            Close
          </Button>
        </CardOverlay> 
      }

      <NavBar />

      <Container>
        {props.auth.error && 
          <ErrorDiv>
            {props.auth.error}
          </ErrorDiv>
        }

        <Panel0>
          <ProfileImage 
            onChange={(e) => setBusiness_image(e)} 
            editable={true} 
            listing={props.auth.profile}   
          />
        </Panel0>

        <Panel1>
          <StyledFormTitle>About</StyledFormTitle>
          <StyledInput 
            required 
            type="text" 
            placeholder="First Name" 
            pattern="[a-zA-Z0-9 ]+" 
            defaultValue={first_name} 
            onChange={(e) => setFirst_name(e.target.value)}
          />

            <StyledInput 
              required 
              type="text" 
              placeholder="Last Name" 
              pattern="[a-zA-Z0-9 ]+" 
              defaultValue={last_name} 
              onChange={(e) => setLast_name(e.target.value)}
            />

            <StyledInput 
              required 
              type="text" 
              placeholder="Business Name" 
              pattern="[a-zA-Z0-9 ]+" 
              onChange={(e) => setBusiness_name(e.target.value)} 
              defaultValue={business_name} 
            />

            <ZipCodeInput value={zip} onChange={(value) => setZip(value)} />

            <TelephoneInput value={phone} onChange={(value) => setPhone(value)} />

            <StyledInput 
              required 
              type="email" 
              placeholder="Email" 
              onChange={(e) => setEmail(e.target.value)} 
              defaultValue={email}   
            />

            <StyledInput 
              required 
              type="url" 
              placeholder="Web Address (including http or https)" 
              onChange={(e) => setBusiness_domain(e.target.value)} 
              defaultValue={business_domain} 
            />
        </Panel1>

        <Panel2>
          <StyledFormTitle>Backlink URL <a href="https://blog.dwellsy.com/tips-dwellsy-lightbox/" target="_blank">learn more</a></StyledFormTitle>
  
          <StyledInput
            type="url"
            placeholder="Backlink URL"
            defaultValue={backlink_url} 
            onChange={(e) => setBacklink_url(e.target.value)} 
          />
            <MarginDiv>
              <StyledFormTitle>What Services Do You Offer?</StyledFormTitle>
            </MarginDiv>
            <ServicesSelector onChange={handleServicesChange} services={lightbox_services} />

            <MarginDiv top="28px" bottom="0" >
              <StyledFormTitle>Service radius (in miles)</StyledFormTitle>
            </MarginDiv>
            <StyledInput 
              type="number" 
              min="1" 
              max="3000" 
              required 
              placeholder="enter a number of miles" 
              onChange={(e) => setRadius(e.target.value)} 
              defaultValue={radius} 
            />
        </Panel2>


      </Container>
      
      <Panel3>
        <MarginDiv top="100px">
          <Button 
            onClick={handleSubmit}
            disabled={props.auth.loading ? true : false } 
          >
            { props.auth.loading ? <ButtonLoading /> : "Update Profile" }
          </Button>
        </MarginDiv>

          <Button 
            variant="textOnly" 
            onClick={loadCard}
            inverted
          >
            Preview Listing
          </Button>
   
      </Panel3>

      </form>
        </Body>
      <Footer />
  
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditProfile);