import StyledInput from 'components/StyledInput';

export const TelephoneInput = (props) => {
  const handlePhone = event => {
    let val = event.target.value;

    val = val.replace(/ /gm, '');
    val = val.replace(/[^0-9]/g, '');

    const npa = val.substr(0, 3);
    const nxx = val.substr(3, 3);
    const tel = val.substr(6, 4);

    let output = "";
    if(val.length <= 2) {
      output = `${npa}`;
    } else if(val.length === 3) {
      output = `${npa}`;
    } else if(val.length <= 5) {
      output = `(${npa}) ${nxx}`;
    } else if(val.length === 6) {
      output = `(${npa}) ${nxx}`;
    } else if(val.length < 6) {
      output = `(${npa}) ${nxx}-`;
    } else {
      output = `(${npa}) ${nxx}-${tel}`;
    }

    props.onChange(output.trim());
  }

  return (
    <StyledInput 
      required 
      type="tel" 
      placeholder="Phone Number" 
      onChange={handlePhone} 
      value={props.value} 
      aria-label="Telephone"
    />
  )
}