import { useState, useEffect } from 'react';
import { CloudinaryImage } from 'components/CloudinaryImage';
import styled from 'styled-components';

const StyledImageUpload = styled.div`
  display: grid;
  background-color: #F7F7F7;
  background-image: url(${props => props.image});
  background-size: cover;
  height: 100%;
  width: 340px;
  height: 220px;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  @media (min-width: 600px) { 
    width: 600px;
    height: 400px;
  }
`

const ResetImageButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 200;
  img {
    max-width: 30px;
  }
`

const CameraIcon = styled.div`
  min-height: 280;
  width: '100%';
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  background-color: '#EFEFF7';

  img {
    padding-top: 60px;
  }
`

const EnvelopeImage = styled.div`
  margin: 40px 0 20px 0;
  display: flex;
  background-color: #6FCBED;
  align-items: center;
  align-self: center;
  justify-content: center;
  border-radius: 100%;
  height: 72px;
  width: 72px;
  position: absolute;
`

const AddLogoText = styled.div`
  color: #757FA4;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
`

const MinDimensions = styled.div`
  height: 13px;
  color: #4F4F4F;
  font-size: 12px;
  line-height: 13px;
`

const DisplayImage = ({ listing }) => {
  if(!listing.business_image) {
    return (
      <StyledImageUpload>
        <CameraIcon>
          <a href={listing.business_domain} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none', justifyContent: 'center'}} target="_blank" rel="noreferrer">
            <img src="assets/icon-camera.svg"  style={{maxWidth:50, opacity: .5}} alt="camera icon" />
            <div style={{color: '#5E5BA7', fontWieght: '600', fontSize: 24,  marginTop: '10px'}}>{listing.business_name}</div>
          </a>
        </CameraIcon>
     
      </StyledImageUpload>
    )
  } else 
    return <CloudinaryImage business_image={listing.business_image} />
}

const EditableImage = ({ listing, onChange=(() => {}) }) => {
  const [ result, setResult ] = useState(listing.business_image);
  const preface = "https://res.cloudinary.com/dwellsy/image/upload/w_480,c_scale";

  useEffect(() => {
    onChange(result);
    // props.addToRegistration({ "business_image": result });
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadWidget = () => {
    window.cloudinary.openUploadWidget({ cloud_name: 'dwellsy', 
    theme: 'minimal',
    showPoweredBy: false,
    styles:{
      width: "100%",
       palette: {
        window: "#FFF",
        windowBorder: "#5E5BA7",
        tabIcon: "#5E5BA7",
        menuIcons: "#5E5BA7",
        textDark: "#000000",
        textLight: "#FFFFFF",
        link:  "#5E5BA7",
        action:  "#5E5BA7",
        inactiveTabIcon: "#5E5BA7",
        error: "#F44235",
        inProgress: "#0078FF",
        complete: "#20B832",
        sourceBg: "#E4EBF1"
      },
     
    },
    
    upload_preset: 'dwellsy-lightbox'}, (error, results) => {
      try {
        const result = results[0];
        const output = `${preface}/v${result.version}/${result.public_id}`;
        setResult(output);
      } catch(e) {
        setResult(null);
      }
    })
  }

  if(result)
    return (

      <StyledImageUpload image={result}>
          <ResetImageButton onClick={() => setResult(null)}>
              <img src="/assets/btn-close.png" alt="reset business pic" />
          </ResetImageButton>
       </StyledImageUpload>

    )

  return (
  
    <StyledImageUpload onClick={uploadWidget}>
      <EnvelopeImage>
        <img src="/assets/bigEnvelope.png" alt="Envelope" /> 
      </EnvelopeImage>
      <AddLogoText>
        Add Logo or Sample Image
      </AddLogoText>
      <MinDimensions>
        Image should be 3:2 formatted to display properly.
      </MinDimensions>
    </StyledImageUpload>


  )
}

export const ProfileImage = ({ listing, editable=false, onChange=(() => {}) }) => {
  if(!editable) 
    return <DisplayImage listing={listing} />
  else 
    return <EditableImage listing={listing} onChange={onChange} />
}
