import styled from 'styled-components';

export const StyledInput = styled.input`
  border-radius: 4px;
  padding: 16px 20px;
  color: #5D5CA9;
  border: 1px solid #A4A4A4;
  width: 100%;
  font-size: 14px;
  margin: 16px 0 0 0;
`

export default StyledInput;