import styled from 'styled-components';

const StyledLightboxLogo = styled.div`
  grid-row: 2;
`

const StyledLightboxLogoImg = styled.img`
  height: 40px;

  @media (min-width: 600px) {
    height: auto;
    margin-top: 10px;
    max-width:200px
  }
`

export const LightboxLogo = () => {
  return (
    <StyledLightboxLogo>
      <StyledLightboxLogoImg src="/assets/logo-lb.png" alt="Lightbox logo" />
    </StyledLightboxLogo>
  )
}

export default LightboxLogo;