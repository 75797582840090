import React from 'react';
import { 
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import store from 'modules/store';
import { Provider } from 'react-redux';
import { ViewportProvider } from 'hooks/useViewport';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Helmet } from 'react-helmet';
import { 
  Directory,
  EditProfile,
  Join,
  Landing,
  Login,
  Logout,
  PremiumContent,
  Register,
} from 'pages';

export const App = () => {
  return (
    <Provider store={store}>
      <ViewportProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dwellsy Lightbox</title>
        </Helmet>
        <Router>
          <Switch>
            <Route exact path="/">
                <ParallaxProvider>
                  <Landing />
                </ParallaxProvider>
            </Route>
            <Route exact path="/logout"><Logout /></Route>
            <Route exact path="/premium"><PremiumContent /></Route>
            <Route exact path="/my"><EditProfile /></Route>
            <Route exact path="/login"><Login /></Route>
            <Route exact path="/join"><Join /></Route>
            <Route exact path="/register"><Register /></Route>
            <Route exact path="/directory"><Directory /></Route>
          </Switch>
        </Router>
      </ViewportProvider>
    </Provider>
  )
};

export default App;
