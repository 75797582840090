import { Types } from 'modules/types';

const initial = {
  user: {},
  logged_in: false,
  registered: false,
  step: 0,
  registration: {
    loading: false,
    error: null,
  },
  login: {
    step: 0,
    token: localStorage.getItem("token") || null,
    email: localStorage.getItem("email") || null,
  },
  loading: false,
  error: null,
  profile: null,
}

export const authReducer = (state=initial, action) => {
  switch(action.type) {
    case Types.PROFILE_FETCH_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case Types.PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        profile: action.payload,
      }
    
    case Types.PROFILE_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        profile: null,
      }

    case Types.PROFILE_UPDATE_STARTED: 
      return {
        ...state,
        loading: true,
        error: null,
      }

    case Types.PROFILE_UPDATE_FAILURE: 
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case Types.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.data,
      }

    case Types.VERIFY_STARTED:
      return {
        ...state,
        loading: true,
      }

    case Types.LOGOUT:
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      return {
        ...state,
        logged_in: false,
        loading: false,
        error: null,
        login: {
          step: 0,
          token: null,
        },
        step: 0,
      }

    case Types.VERIFY_SUCCESS:
      localStorage.setItem("token", action.payload);
      return {
        ...state,
        logged_in: true,
        loading: false,
        error: null,
        login: {
          ...state.login,
          token: action.payload,
        }
      }

    case Types.VERIFY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case Types.LOGIN_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case Types.LOGIN_SUCCESS: 
      const step = state.login.step + 1;

      return {
        ...state,
        login: {
          ...state.login,
          email: action.payload,
          step: step,
        },
        loading: false,
        error: null,
      }
    
    case Types.LOGIN_FAILURE: 
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case Types.REGISTRATION_STARTED: 
      return {
        ...state,
        loading: true,
        error: null,
      }
 
    case Types.REGISTRATION_SUCCESS: 
      return {
        ...state,
        step: state.step+1,
        loading: false,
        error: null,
      }
 
    case Types.REGISTRATION_FAILURE: 
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
 
    case Types.INCREMENT_STEP:
      return {
        ...state,
        step: state.step+1,
      }

    case Types.DECREMENT_STEP:
      return {
        ...state,
        step: state.step-1,
      }

    case Types.ADD_TO_REGISTRATION:
      return {
        ...state,
        registration: {
          ...state.registration,
          ...action.payload,
        },
      }

    case Types.CHECK_BACKLINK_STARTED: 
      return {
        ...state,
        registration: {
          ...state.registration,
          loading: true,
          error: null,
        }
      }
    
    case Types.CHECK_BACKLINK_FAILURE:
      return {
        ...state,
        registration: {
          ...state.registration,
          loading: false,
          error: action.payload,
        }
      }

    case Types.CHECK_BACKLINK_SUCCESS: 
      return {
        ...state,
        registration: {
          ...state.registration,
          backlink_url: action.payload,
          loading: false,
          error: null,
        }
      }

    default: 
     return state;
  }
 }

 export default authReducer;