import styled from 'styled-components';

const Spinner = styled.div`
  margin: 100px auto 0;
  width: 100%;
  text-align: center;
  align-self: center;
  display: flex;
  align-items: center;
`

const Dot = styled.div`
  width: ${props => props.active ? "32px" : "16px" };
  height: ${props => props.active ? "32px" : "16px" };
  margin: 10px;
  background-color: #60C669;

  border-radius: 100%;
  display: inline-block;
`

export const SuccessThrobber = () => {
  return (
    <Spinner>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
      <Dot active></Dot>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
    </Spinner>
  )
}

export default SuccessThrobber;