import styled, { keyframes } from 'styled-components';

const stretch = keyframes`
  0%, 40%, 100% { 
    transform: scaleY(0.3);
    -webkit-transform: scaleY(0.3);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
`

const Spinner = styled.div`
  margin: 0 auto;
  width: 50px;
  height: 30px;
  text-align: center;
  font-size: 10px;

  div {
    margin: 0 1px;
    background-color: #fff;
    opacity: 0.8;
    height: 100%;
    width: 5px;
    display: inline-block;
    -webkit-animation: ${stretch} 1.2s infinite ease-in-out;
    animation: ${stretch} 1.2s infinite ease-out;
  }
`

const Rect1 = styled.div``

const Rect2 = styled.div`
  -webkit-animation-delay: -1.1s !important;
  animation-delay: -1.1s !important;
`

const Rect3 = styled.div`
  -webkit-animation-delay: -1.0s !important;
  animation-delay: -1.0s !important;
`

const Rect4 = styled.div`
  -webkit-animation-delay: -0.9s !important;
  animation-delay: -0.9s !important;
`

const Rect5 = styled.div`
  -webkit-animation-delay: -0.8s !important;
  animation-delay: -0.8s !important;
`

export const ButtonLoading = (props) => {
  return (
    <Spinner>
      <Rect1 />
      <Rect2 />
      <Rect3 />
      <Rect4 />
      <Rect5 />
    </Spinner>
  )
}