import styled from 'styled-components';

const CardService = styled.div`
  height: 50px;
  max-width: 100%;
  border-radius: 4px;
  // background-color: rgba(249,190,137,0.48);
  background-color: whitesmoke;

  display: grid;
  align-items: center;
  justify-content: center;

  color: #121416;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  padding: 5px;

  img {
    padding-top: 10px;
    max-width: 32px;
  }

  div:nth-child(1) {
    display: none;
  }

  div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 350px) {
    height: 84px;

    grid-template-rows: 60% 40%;
    
    div:nth-child(1) {
      grid-row: 1;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    div:nth-child(2) {
      grid-row: 2;
    }
  }
`

  export const cardServiceComponentMap = {
    PHOTOSTILLS: 
      <CardService key="PHOTOSTILLS">
        <div>
          <img src="assets/portrait.png" alt="still photography icon" />
        </div>
        
        <div>Still Photography</div>
      </CardService>,
    PHOTO360: 
      <CardService key="PHOTO360">
        <div>
          <img src="assets/360.png" alt="360 photography icon" />
        </div>

        <div>360° / Matterport</div>
      </CardService>,
    INTERIORSTYLING: 
      <CardService key="INTERIORSTYLING">
        <div>
          <img src="assets/interiorStyling.png" alt="interior photography icon" />
        </div>

        <div>Interior Styling</div>
      </CardService>,
    VIDEO: 
      <CardService key="VIDEO">
        <div>
          <img src="assets/videography.png" alt="videography icon" />
        </div>

        <div>Videography</div>
      </CardService>,
    PHOTOAERIAL: 
      <CardService key="PHOTOAERIAL">
        <div>
          <img src="/assets/aerialPhotography.png" alt="aerial photography icon" />
        </div>
        
        <div>Aerial Photography</div>
      </CardService>,
  }