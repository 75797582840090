import styled from 'styled-components';


const Container = styled.div`
  min-height: 376px;
  background-image: url(/assets/footer-bg2.png);
  background-color: #2e2656;
  background-origin: border-box;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size:  100% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
`

const DwellsyLogo = styled.img`
  margin-top: 100px;
  margin-bottom: 20px;
  max-width: 130px;
`

const Link = styled.a`
  font-size: 20px;
  color: #fff;
  padding: 0 50px;
  line-height: 1.8rem;
  max-width: 600px;
  text-align: center;
  text-decoration: none;
`

export const Footer = () => {
  return (
    <Container>
      <DwellsyLogo src="/assets/logo.png" alt="Dwellsy logo" />
      <Link href="https://www.dwellsy.com" target="_blank">Looking for a home or apartment to rent? Come search the largest inventory of listings at <span style={{color: '#9898F4' }}>Dwellsy.com.</span></Link>
    </Container>
  )
}

export default Footer;