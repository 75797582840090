import styled, { keyframes } from 'styled-components';

const delayInterval = 0.10;

const bouncedelay = keyframes`
  0%, 80%, 100% { -webkit-transform: scale(0); transform: scale(0); } 
  40% { -webkit-transform: scale(1.0); transform: scale(1.0); }
`

const Spinner = styled.div`
  margin: 100px auto 0;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
`
const Dot = styled.div`
  width: 14px;
  height: 14px;
  margin: 10px;
  background-color: #757FA4;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: ${bouncedelay} 1.4s infinite ease-in-out both;
  animation: ${bouncedelay} 1.4s infinite ease-in-out both;
  -webkit-animation-delay: -${props => props.id * delayInterval}s;
  animation-delay: -${props => props.id * delayInterval}s;
`

export const Throbber = () => {
  return (
    <Spinner>
      <Dot id={9}></Dot>
      <Dot id={8}></Dot>
      <Dot id={7}></Dot>
      <Dot id={6}></Dot>
      <Dot id={5}></Dot>
      <Dot id={4}></Dot>
      <Dot id={3}></Dot>
      <Dot id={2}></Dot>
      <Dot id={1}></Dot>
    </Spinner>
  )
}

export default Throbber;