import styled from 'styled-components';
import Button from 'components/Button';
import { useViewport } from 'hooks/useViewport';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PhotographerOptions from 'components/PhotographerOptions';
import { Parallax } from 'react-scroll-parallax';

const Container = styled.div`
  height: 90vh;
  position: relative;
  overflow: hidden;
  background:  linear-gradient(130.1deg, #5E5BA7 0%, #1E133E 80%);
  display: grid;
  grid-template-columns: repeat(20, 5vw);
  grid-template-rows: 25px 65px 10px repeat(2, 1fr);

  @media (min-width: 600px) {

    height: 100vh;
  }
  @media (min-width: 1100px) {
    height: 90vh;

  }
  @media (min-width: 1600px) {

  }
`

const MainGraphicContainer = styled.div`
   position: absolute;
    right:  0;
    top: 45%;
    overflow: visiible;
    padding: 40px;
    height: 46vh;
    width:  100%;
  
  
  @media (min-width: 600px) {
    top: 45%;
    width:  100%;
    height: 100vh;
    bottom:  0;
  }
  @media (min-width: 1100px) {
    width:  80%;
    height: 90vh;
    right:  -20%;
    top: 20%;
  }
  @media (min-width: 1600px) {
    top: 30%;
    width:  80%;
    height: 90vh;
  }
  `
const LightBoxBorder = styled.div`
    background: linear-gradient(to right, #36245B, #5E5BA7);
    width: 100%;
    height: 100%;
    padding: 1px;
    border-radius: 14px;
    transform: rotate(-10deg);
    max-width: 1000px;
    box-shadow:0px 0px 200px 0px rgba(173,184, 227, .4);
    // border: 1px solid red;

  @media (min-width: 600px) {
  }
  @media (min-width: 1100px) {
  }
  @media (min-width: 1600px) {
  }
`


const LightBoxGraphic = styled.div`

    width: 140%;
    height: 46vh;
    padding: 30px;
    border-radius: 13px;
    max-width: 1000px;
    background: linear-gradient(173deg, rgba(34,34,34,1) 0%, rgba(0,0,0,1) 40%, rgba(29,29,29,1) 41%, rgba(55,55,55,1) 100%);
    

  @media (min-width: 600px) {
    width: 140%;
    height: 80vh;
    padding: 50px;
    

  }
  @media (min-width: 1100px) {

    width: 100%;
    height: 100vh;
    padding: 50px;
    // border: 1px solid green;

  
  }
  @media (min-width: 1600px) {
   
  }
`

const LightBoxLight = styled.div`

    overflow: visible;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: linear-gradient(to right, #feefd5, #fffaf3);
`
const ParallaxImageWrapper = styled.div`
    width: 100%;
    height: 46vh;
    top: 17%;
    left: 15%;
    position: absolute;
    transform: rotate(-10deg);
    // max-width: 980px;
    // border: 1px solid red;

    @media (min-width: 500px){
      width: 99%;
    height: 55vh;
    top: 16%;
    left: 12%;
    }

  @media (min-width: 600px) {
   width: 102%;
    top: 8%;
    left: 4%;
  }


  @media (min-width: 800px) {
    width: 94%;
    top: 10%;
    left: 4%;
  }
  @media (min-width: 1100px) {
    //  border: 1px solid red;
    width: 80%;
    height: 100%;
    top: 14%;
    left: 6%;

  
  }
  @media (min-width: 1600px) {

    width: 66%;
    top: 14%;
    left: 5%;
 
}
  
  }
`

const PhotoLevel1 = styled.div`

    opacity: .4;  
    width: 100%;
    padding-bottom: 50%;
    border-radius: 10px;
    min-width: 200px;
    background-size: cover;
    background-image: url(/assets/fp-hero-photo-level1.jpg);

  @media (min-width: 600px) {

  }
  @media (min-width: 1100px) {

  }
  @media (min-width: 1600px) {
  }
`


const PhotoLevel2= styled.div`
    opacity: 1;  
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.5); 
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.5);
    z-index: 1000;
    width: 50%;
    padding-bottom: 50%;
    border-radius: 10px;
    min-width: 200px;
    background-size: cover;
    background-image: url(/assets/fp-hero-photo-level2.jpg);

  @media (min-width: 600px) {

  }
  @media (min-width: 1100px) {

  }
  @media (min-width: 1600px) {

  }
`

const PhotoLevel3= styled.div`

    -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.5); 
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.5);
    width: 40%;
    padding-bottom: 40%;
    border-radius: 10px;
    min-width: 200px;
    background-size: cover;
    background-image: url(/assets/fp-hero-photo-level3.jpg);

  @media (min-width: 600px) {
     width: 60%;

  }
  @media (min-width: 1100px) {
    width: 60%;

  }
  @media (min-width: 1600px) {

  }
`

const PhotoLevel4= styled.div`

    -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.5); 
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.5);
    width: 40%;
    padding-bottom: 40%;
    border-radius: 10px;
    min-width: 200px;
    background-size: cover;
    background-image: url(/assets/fp-hero-photo-level4.jpg);

  @media (min-width: 600px) {

  }
  @media (min-width: 1100px) {

  }
  @media (min-width: 1600px) {

  
  }
`


const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`

const MainContainer = styled.div`
  grid-column: 2 / 20;
  grid-row: 2;
  display: grid;
  grid-template-rows: 70px 40px 1fr 90px;

  @media (min-width: 600px) {
    grid-row: 4;
    grid-template-rows: 150px 80px 1fr 150px;
  }
`

const DwellsyLogo = styled.div`
  grid-row: 1;
`

const DwellsyLogoImg = styled.img`
  height: 60px;

  @media (min-width: 600px) {
    height: 120px;
  }
`

const LightboxLogo = styled.div`
  grid-row: 2;
`

const LightboxLogoImg = styled.img`
  max-height: 70px;
  height: 35px;
  @media (min-width: 600px) {
    height: auto;
  }
`

const MainText = styled.p`
  grid-row: 3;
  position: relative;
  z-index: 3000;
  color: #FFF;
  font-size: 18px;
  line-height: 28px;

  @media (min-width: 600px) {
    width: 34ch;

    font-size: 20px;
    line-height: 28px;
  }
`

const UserOptionsContainer = styled.div`
  grid-row: 4;
  position: relative;
  z-index: 3000;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 400px) {
    grid-template-columns: 200px 100px;
  }
  @media (min-width: 600px) {
    grid-template-columns: 200px 140px;
  }
`

const UserOptionsViewDirectory = styled.div`
  grid-column: 1;
  display: flex;
  align-items: center;
`

const UserOptionsJoin = styled.div`
  grid-column: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 600px) {
    justify-content: center;
  }
`

const PhotographerOptionsContainer = styled.div`
  grid-column: 19; 
  grid-row: 2;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (min-width: 600px) {
    grid-column: 17;
  }
`
const LenFlareEl1 = styled.div`
  position: relative;
  left: 5%;
  width: 150px;
  height: 150px;
  border-radius: 75px;
  background-color: rgba(90, 90, 167, .1);

`

const LenFlareEl2 = styled.div`
  position: relative;
  left: 50%;
  width: 50px;
  height: 50px;
  border-radius: 75px;
  background-color: rgba(90, 90, 167, .1);
`
const Photocredits = styled.div`
    padding: 10px 20px; 
    text-align: right;
    font-size: 11px;
    a{
      padding-left: 6px
    }
`



const LenFlare1 = () => (
    <Parallax y={[-500, -700]}> 
      <LenFlareEl1> 
      <LenFlare2 />
      </LenFlareEl1>
    </Parallax>
);
 
const LenFlare2 = () => (
    <Parallax x={[-20, 30]}> 
      <LenFlareEl2 /> 
    </Parallax>
);



const ParallaxFrame = () => (
      <Parallax
      y={[-4, 16]}
      x={[-2, 1]}
      > 
        <LightBoxBorder>
          <LightBoxGraphic>
            <LightBoxLight>
                <PhotoLevel1 /> 
              </LightBoxLight>
          </LightBoxGraphic>
      </LightBoxBorder>
      </Parallax>
);



const ParallaxImage2 = () => (
    <Parallax
    y={[100, -10]}
    x={[50, 50]}
    > 
      <PhotoLevel2 /> 
    </Parallax>
);

const ParallaxImage3 = () => (
  
    <Parallax
    y={[-10, -90]}
    x={[20, 20]}
    > 
      <PhotoLevel3 /> 
    </Parallax>
);

const ParallaxImage4 = () => (

    <Parallax
    y={[-100, 0]}
    x={[10, 10]}
    > 
      <PhotoLevel4 /> 
    </Parallax>
);





export const Panel1 = () => {
  const { width } = useViewport();

  return (
    <>
    <Container>
      <MainContainer>
        <MainGraphicContainer>
          <ParallaxFrame />
            <ParallaxImageWrapper>
        
              <ParallaxImage2 /> 
              <ParallaxImage3 /> 
              <ParallaxImage4 />
            </ParallaxImageWrapper>
            <LenFlare1 /> 
        </MainGraphicContainer>
        <DwellsyLogo>
          <DwellsyLogoImg src="/assets/logo.png" alt="Dwellsy logo" />
        </DwellsyLogo>

        <LightboxLogo>
          <LightboxLogoImg src="/assets/logo-lb.png" alt="Lightbox logo" />
        </LightboxLogo>

        <MainText>
          A simple, location-based directory of talented professionals who offer photo, video, and styling services to residential property managers who need images to market rental homes.
        </MainText>

        <UserOptionsContainer>
          <UserOptionsViewDirectory>
            <Link to="/directory">
              <Button height="50px" variant={width < 300 ? 'small' : null} >
                View Directory
              </Button>
            </Link>
          </UserOptionsViewDirectory>

          <UserOptionsJoin>
            <StyledLink to="/join">
              <Button variant="textOnly">
                Join
              </Button>
            </StyledLink>
          </UserOptionsJoin>
        </UserOptionsContainer>
      </MainContainer>
      
      <PhotographerOptionsContainer>
        <PhotographerOptions />
      </PhotographerOptionsContainer>
    </Container>
    <Photocredits>
      Photos by 
      <a href="https://www.focusedlovephotography.com/interiors" target="_blank" rel="noreferrer" style={{color: '#3399FF'}}>Focused Love Photography</a>
      <a href="https://www.deandaphotography.com/real-estate-photography" target="_blank" rel="noreferrer" style={{color: '#3399FF'}}>DeAnda Photography</a>
      <a href="https://www.cheninboutwell.com/interiors" target="_blank" rel="noreferrer" style={{color: '#3399FF'}}>Chenin Boutwell Photography</a>
    </Photocredits>
    
    </>
  )
}

export default Panel1;
