import styled from 'styled-components';

const StyledErrors = styled.div`
  color: red;
  text-align: left;
  margin: 1em 0;

  div {
    margin: 0.5em 0;
  }
`

export const ErrorList = (props) => {
  if(props.errors) {
    return Object.keys(props.errors).map((key) => {
      if(key === "username") {
        return null;
      }
      return <div key={key}>{props.errors[key]}</div>
    });
  }

  return null;
}


export const Errors = (props) => {
  if(props.errors) {
    return <StyledErrors><ErrorList errors={props.errors} /></StyledErrors>
  }

  return null;
}