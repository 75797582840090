import React from 'react';
import styled from 'styled-components';
import Throbber from 'components/Throbber';

const VerificationText = styled.h4`

  font-weight: 500;
  font-size: 14px;
  color: #293359;
  margin: 80px 0 -80px 0;
`

export const VerifyingLink = () => {
  return (
    <React.Fragment>
      <VerificationText>Verification in Progress</VerificationText>
      <Throbber />
    </React.Fragment>
  )
}

export default VerifyingLink;