import styled from 'styled-components';
import Button from 'components/Button';
import { useViewport } from 'hooks/useViewport';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: block;
  margin-top: 80px;
  margin-left: 7vw;
  margin-right: 7vw;


  @media (min-width: 650px) {
    min-height: 750px;
    margin: 0;
    display: grid;
    grid-template-columns: 2% 45% 1fr 2%;
    grid-template-rows: 70px 1fr;
    grid-gap: 40px;
  }

  @media (min-width: 1000px) {
    background-image: url(/assets/section-ph-graphic@2x.png);
    background-origin: border-box;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
    background-size: 600px;
  }

  @media (min-width: 1200px) {
    max-width: 1280px;
    margin: 0 auto;
    grid-template-columns: 2% 40% 1fr 1fr 2%;
    background-position-x: center;
    background-size: 700px;
  }
`

const MainContainer = styled.div`
  grid-column: 2;
  grid-row: 2;

  margin-left: 30px;
  margin-right: 30px;

  @media (min-width: 650px) {
    margin: 0;
    padding-right: 20px;

    max-width: 600px;
  }
`

const MainLabel = styled.div`
  grid-row: 1;

  color: #7171FF;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.6px;
  line-height: 33px;

  margin-bottom: 25px;

  @media (min-width: 650px) {
    font-size: 20px;
  }
`

const MainHeader = styled.h2`
  color: #000;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -0.9px;
  line-height: 49px;

  @media (min-width: 650px) {
    grid-row: 2;
    font-size: 44px;
    letter-spacing: -1.1px;
    line-height: 60px;
  }
`

const MainText = styled.p`
  grid-row: 3;

  color: #000;
  font-size: 14px;
  line-height: 24px;

  margin-bottom: 30px;
`

const JoinButtonContainer = styled.div`
  grid-row: 4;

  display: flex;
  justify-content: center;

  margin-bottom: 20px;

  @media (min-width: 650px) {
    display: block;
  }
`

const Info1Container = styled.div`
  grid-column: 3;
  grid-row: 2;
`

const Info1Icon = styled.div`
  grid-row: 1;

  margin-bottom: 10px;
`

const Info1Header = styled.h3`
  grid-row: 2;

  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
`

const Info1Text = styled.p`
  grid-row: 3;

  color: #000;
  font-size: 14px;
  line-height: 24px;

  margin-bottom: 30px;
`

const Info2Container = styled.div`
  grid-column: 3;
  grid-row: 3;

  @media (min-width: 1200px) {
    grid-column: 4;
    grid-row: 2;
  }
`

const Info2Icon = styled.div`
  grid-row: 1;

  margin-bottom: 10px;
`

const Info2Header = styled.h3`
  grid-row: 2;

  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
`

const Info2Text = styled.div`
  grid-row: 3;

  color: #000;
  font-size: 14px;
  line-height: 24px;

  margin-top: 20px;
  margin-bottom: 60px;
`

const BackgroundLinkContainer = styled.div`
  grid-row: 4;

  display: flex;
  justify-content: center;

  margin-top: 20px;

  @media (min-width: 650px) {
    display: block;
  }
`

const Icon = styled.img`
  max-width: 40px;
  margin-right: 10px;
`;

const BackgroundLink = styled.a`
  color: #5E5BA7;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.35px;
  line-height: 19px;
  text-decoration: none;
`

export const Panel2 = () => {
  const { width } = useViewport();

  if (width < 650) {
    return (
      <Container>
        <MainLabel>
          For Photographers
        </MainLabel>

        <MainHeader>
          Build Your Real Estate Photography Business
        </MainHeader>

        <MainText>
          Thousands of property managers use Dwellsy for their listings and they need great marketing images. Joining Lightbox is a free, easy way to get exposure to these prospective clients.
        </MainText>
      
        <Info1Icon>
           <Icon alt="Diversify icon" src={`/assets/icon-diverse.svg`} />
        </Info1Icon>
        
        <Info1Header>
          Diversify Your Income Streams
        </Info1Header>

        <Info1Text>
          In a time when weddings & events have dried up, real estate photography is a great additional source of revenue for talented professionals. And if you have already been serving the “For-Sale” market, rental properties gives you a whole new segment!
        </Info1Text>

        <Info2Icon>
          <Icon alt="Portfolio icon" src={`/assets/icon-portfolio.svg`} />
        </Info2Icon>
        
        <Info2Header>
          Access to Exclusive Business Content
        </Info2Header>

        <Info2Text>
          Besides you exposure to property managers who need photography, Dwellsy provides expert content to improve your technical skills, bespoke analysis of your local real estate market and more... All for FREE when you sign up!
        </Info2Text>

        <JoinButtonContainer>
          <Link to="/join">
            <Button>
              Join
            </Button>  
          </Link>
        </JoinButtonContainer>

        <BackgroundLinkContainer>
          <BackgroundLink href="/">Background & FAQ</BackgroundLink>
        </BackgroundLinkContainer>
      </Container>
    )
  } else if (width < 1200) {
    return (
      <Container>
        <MainContainer>
          <MainLabel>
            For Photographers
          </MainLabel>

          <MainHeader>
            Build Your Real Estate Photography Business
          </MainHeader>

          <MainText>
            Thousands of property managers use Dwellsy for their listings and they need great marketing images. Joining Lightbox is a free, easy way to get exposure to these prospective clients.
          </MainText>

          <JoinButtonContainer>
            <Link to="/join">
              <Button>
                Join
              </Button>  
            </Link>
          </JoinButtonContainer>
        </MainContainer>

        <Info1Container>
          <Info1Icon>
            <Icon alt="Diversify icon" src={`/assets/icon-diverse.svg`} />
          </Info1Icon>
          
          <Info1Header>
            Diversify Your Income Streams
          </Info1Header>

          <Info1Text>
            In a time when weddings & events have dried up, real estate photography is a great additional source of revenue for talented professionals. And if you have already been serving the “For-Sale” market, rental properties gives you a whole new segment!
          </Info1Text>

          <Info2Icon>
            <Icon alt="Portfolio icon" src={`/assets/icon-portfolio.svg`} />
          </Info2Icon>
          
          <Info2Header>
            Access to Exclusive Business Content
          </Info2Header>

          <Info2Text>
            Besides exposure to property managers who need photography, Dwellsy provides expert content to improve your technical skills, bespoke analysis of your local real estate market and more... All for FREE when you sign up!

            <BackgroundLinkContainer>
              <BackgroundLink href="#faq">Background &amp; FAQ</BackgroundLink>
            </BackgroundLinkContainer>
          </Info2Text>
        </Info1Container>
      </Container>
    )
  } else {
    return (
      <Container>
        <MainContainer>
          <MainLabel>
            For Photographers
          </MainLabel>

          <MainHeader>
            Build Your Real Estate Photography Business
          </MainHeader>

          <MainText>
            Thousands of property managers use Dwellsy for their listings and they need great marketing images. Joining Lightbox is a free, easy way to get exposure to these prospective clients.
          </MainText>

          <JoinButtonContainer>
            <Link to="/join">
              <Button>
                Join
              </Button>  
            </Link>
          </JoinButtonContainer>
        </MainContainer>

        <Info1Container>
          <Info1Icon>
              <Icon alt="Diversify icon" src={`/assets/icon-diverse.svg`} />
          </Info1Icon>
          
          <Info1Header>
            Diversify Your Income Streams
          </Info1Header>

          <Info1Text>
            In a time when weddings & events have dried up, real estate photography is a great additional source of revenue for talented professionals. And if you have already been serving the “For-Sale” market, rental properties gives you a whole new segment!
          </Info1Text>
        </Info1Container>

        <Info2Container>
          <Info2Icon>
          
             <Icon alt="Portfolio icon" src={`/assets/icon-portfolio.svg`} />
          </Info2Icon>
          
          <Info2Header>
            Access to Exclusive Business Content
          </Info2Header>

          <Info2Text>
            Besides exposure to property managers who need photography, Dwellsy provides expert content to improve your technical skills, bespoke analysis of your local real estate market and more... All for FREE when you sign up!

            <BackgroundLinkContainer>
              <BackgroundLink href="#faq">Background &amp; FAQ</BackgroundLink>
            </BackgroundLinkContainer>
          </Info2Text>
        </Info2Container>
      </Container>
    )
  }
}

export default Panel2;