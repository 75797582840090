import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import StyledInput from 'components/StyledInput';
import { MarginDiv } from 'primitives';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { verify } from 'modules/actions/auth';
import VerifyPageBlurb from 'components/blurbs/VerifyPageBlurb';
import { ButtonLoading } from 'components/ButtonLoading';

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  verify: (data) => dispatch(verify(data)),
})

const FormContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  width: 100%;
  margin-top: 50px;
  max-width: 400px;
  padding-bottom: 50px;
`

const InputContainer = styled.div`
  padding: 0 22px 0 23px;
  text-align: center;
`

const StyledFormTitle = styled.div`
  padding: 100px 0 0px 0;
  color: #757FA4;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
`

const ErrorDiv = styled.div`
  margin: 2em 0 2em 0;
  font-weight: bold;
  color: red;
`
const BlurbBlock = styled.div`
p{
  font-size: 14px;
  color: #000;
  }
`

export const RegistrationVerifyForm = (props) => {
  const form = useRef(null);
  const [ token, setToken ] = useState("");

  const handleSubmit = event => {
    event.preventDefault();

    const valid = form.current.reportValidity();
    if(valid) {
      const email = props.auth.registration?.email;

      const data = {
        email: email,
        token: token,
      }

      props.verify(data);
    }
  }

  if(props.auth?.login?.token)
    return <Redirect to="/register" />

  return (
    <React.Fragment>
      <FormContainer>
        <InputContainer>
          <StyledFormTitle>Verify Your Email</StyledFormTitle>
          <BlurbBlock><VerifyPageBlurb /></BlurbBlock>
          <form ref={form} onSubmit={handleSubmit}>
            <StyledInput required type="number" onChange={(e) => setToken(e.target.value)}/>

            { props.auth?.error ? <ErrorDiv>{props.auth?.error}</ErrorDiv> : null }

            <MarginDiv>
              <Button 
                onClick={handleSubmit}
              >
                { props.auth.loading ? <ButtonLoading /> : "Continue" }
              </Button>
            </MarginDiv>
          </form>


          <MarginDiv>
            <Link to="/">
              <Button variant="textOnly" inverted>
                Cancel
              </Button>
            </Link>
          </MarginDiv>
        </InputContainer>
      </FormContainer>
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegistrationVerifyForm);